import dayjs from "dayjs";

let REFERENCE = dayjs();

let TODAY = REFERENCE.clone().startOf("day");
let YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
let A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");

export const DATE_FORMATS = {
  BASIC_DATE_TIME: "MMM DD, YYYY hh:mm A",
  SIMPLE_DATE: "MMM DD, YYYY",
};

export const format = (date: string, format?: string) => {
  if (!date || date === "") return date;

  return dayjs(date).format(format ? format : DATE_FORMATS.BASIC_DATE_TIME);
};

export const isToday = (date) => dayjs(date).isSame(TODAY, "day");

export const isYesterday = (date) => dayjs(date).isSame(YESTERDAY, "day");

export const isWithinAWeek = (date) => dayjs(date).isAfter(A_WEEK_OLD);

export const isTwoWeeksOrMore = (date) => !isWithinAWeek(dayjs(date));
