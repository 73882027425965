import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import type { ReactNode } from "react";
import fetch from "src/services/fetch";
import ISample from "src/types/ISample";
import * as factory from "src/factory/sample";

type GetSamples = {
  type: "GET_SAMPLES";
  payload: {
    samples: ISample[];
    ruleUniqueID: string;
  };
};

type SetCurrentSamples = {
  type: "SET_CURRENT_SAMPLES";
  payload: {
    samples: ISample[];
  };
};

type IsLoading = {
  type: "IS_LOADING";
  payload: {
    isLoading: boolean;
  };
};

type Action = GetSamples | SetCurrentSamples | IsLoading;

interface SamplesProps {
  children: ReactNode;
}

interface SamplesState {
  isLoading: boolean;
  samples: ISample[];
  cachedSamples: any;
}

interface ContextValue {
  state?: SamplesState;
  dispatch?: (value: Action) => void;
  getSamples?: (config: any) => void;
}

const SamplesContext = createContext<ContextValue>({});
const useSamples = (): ContextValue => useContext(SamplesContext);

const reducer = (state: SamplesState, action: Action): SamplesState => {
  let newState = state;

  switch (action.type) {
    case "GET_SAMPLES": {
      const { samples, ruleUniqueID } = action.payload;
      newState = {
        ...state,
        cachedSamples: { ...state.cachedSamples, [ruleUniqueID]: samples },
        samples,
      };

      break;
    }

    case "SET_CURRENT_SAMPLES": {
      const { samples } = action.payload;
      newState = { ...state, samples };

      break;
    }

    case "IS_LOADING": {
      const { isLoading } = action.payload;
      newState = {
        ...state,
        isLoading,
      };

      break;
    }
  }

  return newState;
};

const SamplesProvider: React.FC<SamplesProps> = ({
  children,
}: SamplesProps) => {
  const initialSampleState: SamplesState = {
    samples: [],
    cachedSamples: [],
    isLoading: false,
  };

  const [state, dispatch] = useReducer(reducer, initialSampleState);

  const getSamples = useCallback(
    (config = { params: {} }) => {
      const { ruleUniqueID } = config.params;

      const cachedData = state.cachedSamples[ruleUniqueID];

      if (cachedData) {
        dispatch({
          type: "SET_CURRENT_SAMPLES",
          payload: { samples: cachedData },
        });

        return;
      }

      dispatch({
        type: "IS_LOADING",
        payload: { isLoading: true },
      });

      dispatch({
        type: "SET_CURRENT_SAMPLES",
        payload: { samples: [] },
      });

      return fetch
        .get(`/Lookup/GetSamples`, config)
        .then((response) => {
          let samples = response.data;
          samples = samples.map((sample) =>
            factory.createSample(sample).deserialize(sample)
          );

          dispatch({
            type: "GET_SAMPLES",
            payload: { samples, ruleUniqueID },
          });

          dispatch({
            type: "IS_LOADING",
            payload: { isLoading: false },
          });
        })
        .catch((err) => {
          dispatch({
            type: "IS_LOADING",
            payload: { isLoading: false },
          });
        });
    },
    [state]
  );

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      getSamples,
    }),
    [state, getSamples]
  );

  return (
    <SamplesContext.Provider value={value}>{children}</SamplesContext.Provider>
  );
};

export { SamplesProvider, useSamples };
