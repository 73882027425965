export enum Role {
  EXTERNAL_USER_READ_ONLY = 0,
  EXTERNAL_USER_FULL_ACCESS = 1,
  INTERNAL_USER_READ_ONLY = 2,
  INTERNAL_USER_FULL_ACCESS = 3,
  EXTERNAL_USER_ADMIN = 4,
  INTERNAL_USER_ADMIN = 10,
}
export interface IRole {
  id?: string;
  roleID?: number;
  name?: string;
  displayOrder?: number;
}
