import faker from "faker";
import type IRule from "src/types/IRule";
import { withConstructor } from "./mixins/constructor";
import { IWithFaker, withFaker } from "./mixins/faker";
import { pipe } from "./mixins/pipe";
import type ISample from "src/types/ISample";
import dayjs from "dayjs";
import { DATE_FORMATS, format } from "src/utils/date";
import { removeEmpty } from "src/utils/object";
import { clamp } from "src/utils/number";
import { isEmptyValue } from "src/utils/string";
import { Scalable } from "src/constants/scalable";

const initialState: Partial<IRule> = {
  ruleUniqueID: "",
  ruleID: null,
  programName: "",
  subProgramName: null,
  customerName: "",
  scalable: "",
  make: "",
  model: "",
  component: "",
  fuelType: "",
  oilManufacturer: null,
  oilBrand: null,
  oilGrade: null,
  resultCode: null,
  programCode: "",
  progID: 0,
  lowerLimit: {
    isLower: true,
    limitUnit: "",
    aLimit: null,
    bLimit: null,
    cLimit: null,
    aComment: "",
    bComment: "",
    cComment: "",
    aCommentIndex: "",
    bCommentIndex: "",
    cCommentIndex: "",
  },
  upperLimit: {
    isLower: false,
    limitUnit: "",
    aLimit: null,
    bLimit: null,
    cLimit: null,
    aComment: null,
    bComment: null,
    cComment: null,
    aCommentIndex: "",
    bCommentIndex: "",
    cCommentIndex: "",
  },
  rateControl: {
    aRateOfChange: null,
    bRateOfChange: null,
    cRateOfChange: null,
    rateOfChangeUnit: null,
    rateOfChangeValue: null,
  },
  testNo: null,

  deserialize: function (rule: IRule) {
    Object.getOwnPropertyNames(this).forEach((prop) => {
      if (rule[prop] !== undefined) this[prop] = rule[prop];
    });

    this["upperLimit_aLimit"] = this.upperLimit?.aLimit;
    this["upperLimit_aComment"] = this.upperLimit?.aComment;
    this["upperLimit_aCommentIndex"] = this.upperLimit?.aCommentIndex;

    this["upperLimit_bLimit"] = this.upperLimit?.bLimit;
    this["upperLimit_bComment"] = this.upperLimit?.bComment;
    this["upperLimit_bCommentIndex"] = this.upperLimit?.bCommentIndex;

    this["upperLimit_cLimit"] = this.upperLimit?.cLimit;
    this["upperLimit_cComment"] = this.upperLimit?.cComment;
    this["upperLimit_cCommentIndex"] = this.upperLimit?.cCommentIndex;

    this["lowerLimit_aLimit"] = this.lowerLimit?.aLimit;
    this["lowerLimit_aComment"] = this.lowerLimit?.aComment;
    this["lowerLimit_aCommentIndex"] = this.lowerLimit?.aCommentIndex;

    this["lowerLimit_bLimit"] = this.lowerLimit?.bLimit;
    this["lowerLimit_bComment"] = this.lowerLimit?.bComment;
    this["lowerLimit_bCommentIndex"] = this.lowerLimit?.bCommentIndex;

    this["lowerLimit_cLimit"] = this.lowerLimit?.cLimit;
    this["lowerLimit_cComment"] = this.lowerLimit?.cComment;
    this["lowerLimit_cCommentIndex"] = this.lowerLimit?.cCommentIndex;

    this["aRateOfChange"] = this.rateControl?.aRateOfChange;
    this["bRateOfChange"] = this.rateControl?.bRateOfChange;
    this["cRateOfChange"] = this.rateControl?.cRateOfChange;
    this["rateOfChangeUnit"] = this.rateControl?.rateOfChangeUnit;
    this["rateOfChangeValue"] = this.rateControl?.rateOfChangeValue;

    this["limitUnit"] =
      this.lowerLimit?.limitUnit ?? this.upperLimit?.limitUnit;

    return this as IRule;
  },
  serialize: function (parameters?: any) {
    let data: any = {
      progID: this.progID,
      testNo: this.testNo,
      rateOfChangeUnit: this.rateControl?.rateOfChangeUnit,
      remarks: this.remarks,
      rateControl: {
        ...this.rateControl,
      },
    };

    // new rule
    if (parameters) {
      data = {
        ...data,
        progID: parameters.selections["programs"]?.id,
        subProgID: parameters.selections["subPrograms"]?.id,
        customerID: parameters.selections["clients"]?.id,
        makeID: parameters.selections["makes"]?.id,
        modelID: parameters.selections["models"]?.id,
        compType: parameters.selections["componentTypes"]?.key,
        fuelType: parameters.selections["fuelTypes"]?.key,
        oilID: parameters.selections["oilId"],
        testNo: parameters.selections["resultCodes"]?.testNo,
        rateOfChangeUnit: parameters.selections["getRateChangeUnits"]?.id,
        rateControl: {
          ...data.rateControl,
          rateOfChangeUnit: parameters.selections["getRateChangeUnits"]?.id,
        },
      };
    }

    if (this.scalable === Scalable.FALSE) {
      delete data.rateControl;
      delete data.rateOfChangeUnit;
    }

    if (this.useLowerLimit()) {
      data = {
        ...data,
        lowerLimit: {
          isLower: true,
          ...this.lowerLimit,
          aLimit: Number(this.lowerLimit?.aLimit),
          bLimit: Number(this.lowerLimit?.bLimit),
          cLimit: Number(this.lowerLimit?.cLimit),
        },
      };
    }

    if (this.useUpperLimit()) {
      data = {
        ...data,
        upperLimit: {
          isLower: false,
          ...this.upperLimit,
          aLimit: Number(this.upperLimit?.aLimit),
          bLimit: Number(this.upperLimit?.bLimit),
          cLimit: Number(this.upperLimit?.cLimit),
        },
      };
    }

    return removeEmpty(data);
  },
  getGraphData: function (samples?: ISample[]) {
    if (!samples) return;

    const { min, max } = this.getMarginOffsets();

    let out = samples.map((sample, i) => ({
      units: `Data point ${i + 1}`,
      lowerALimit: this?.lowerLimit?.aLimit,
      lowerBLimit: this?.lowerLimit?.bLimit,
      lowerCLimit: this?.lowerLimit?.cLimit,
      upperALimit: this?.upperLimit?.aLimit,
      upperBLimit: this?.upperLimit?.bLimit,
      upperCLimit: this?.upperLimit?.cLimit,
      value: clamp(Number(sample.result), min, max),
      originalValue: Number(sample.result),
      date: dayjs(sample.sampleDateReviewed).toDate(),
      formattedDate: format(
        sample.sampleDateReviewed,
        DATE_FORMATS.SIMPLE_DATE
      ),
      sampleNo: sample.sampleNo,
      labNo: sample.labNo,
    }));

    return out;
  },
  getMarginOffsets: function () {
    let max = 0;
    let min = 0;
    const offsetPercentage = 0.2;

    if (this.useLowerLimit()) {
      min = Number(this.lowerLimit?.cLimit);
      max = Number(this.lowerLimit?.aLimit);
    }
    if (this.useUpperLimit()) {
      min = Number(this.upperLimit?.aLimit);
      max = Number(this.upperLimit?.cLimit);
    }

    if (this.useUpperLimit() && this.useLowerLimit()) {
      min = Number(this.lowerLimit?.cLimit);
      max = Number(this.upperLimit?.cLimit);
    }

    const offset = Math.floor(max * offsetPercentage);

    // return { min: min - offset, max: max + offset }; removed to keep min mark at zero.
    return { min: 0, max: max + offset };
  },
  generateMarks: function () {
    const { min, max } = this.getMarginOffsets();

    return [
      { label: min, value: min },
      { label: max * 0.75, value: max * 0.75 },
      { label: max * 0.5, value: max * 0.5 },
      { label: max * 0.25, value: max * 0.25 },
      { label: max, value: max },
    ];
  },
  getLimits: function () {
    const limits = [];

    if (this.useLowerLimit()) {
      limits.push(Number(this.lowerLimit?.cLimit));
      limits.push(Number(this.lowerLimit?.bLimit));
      limits.push(Number(this.lowerLimit?.aLimit));
    }

    if (this.useUpperLimit()) {
      limits.push(Number(this.upperLimit?.cLimit));
      limits.push(Number(this.upperLimit?.bLimit));
      limits.push(Number(this.upperLimit?.aLimit));
    }

    return limits;
  },
  useLowerLimit: function () {
    if (!this.lowerLimit) return false;

    const { aLimit, bLimit, cLimit } = this.lowerLimit;

    return Boolean(aLimit || bLimit || cLimit);
  },
  useUpperLimit: function () {
    if (!this.upperLimit) return false;

    const { aLimit, bLimit, cLimit } = this.upperLimit;
    return Boolean(aLimit || bLimit || cLimit);
  },
  copy: function (parameters?: any) {
    // Temp: clear rule for copy feture

    this.resultCode = null;
    this.component = null;
    this.rateControl.rateOfChangeUnit = null;

    return this.serialize(parameters);
  },
  isValid: function () {
    let errors = null;

    if (this.useLowerLimit()) {
      if (!this.lowerLimit?.cComment)
        errors = {
          ...errors,
          lc: "Lower comment C is missing",
        };

      if (!this.lowerLimit?.cCommentIndex)
        errors = {
          ...errors,
          lc: "Lower comment C code is missing",
        };

      if (isEmptyValue(this.lowerLimit?.cLimit === null))
        errors = {
          ...errors,
          lc: "Lower limit C value is missing",
        };

      if (!this.lowerLimit?.bComment)
        errors = {
          ...errors,
          lb: "Lower comment B is missing",
        };

      if (!this.lowerLimit?.bCommentIndex)
        errors = {
          ...errors,
          lb: "Lower comment B code is missing",
        };

      if (isEmptyValue(this.lowerLimit?.bLimit))
        errors = {
          ...errors,
          lb: "Lower limit B value is missing",
        };

      if (!this.lowerLimit?.aComment)
        errors = {
          ...errors,
          la: "Lower comment A is missing",
        };

      if (!this.lowerLimit?.aCommentIndex)
        errors = {
          ...errors,
          la: "Lower comment A code is missing",
        };

      if (isEmptyValue(this.lowerLimit?.aLimit))
        errors = {
          ...errors,
          la: "Lower limit A value is missing",
        };

      if (isEmptyValue(this.lowerLimit?.limitUnit)) {
        errors = {
          ...errors,
          limitUnit: "Limit unit value is missing",
        };
      }
    }

    // Upper

    if (this.useUpperLimit()) {
      if (!this.upperLimit?.cComment)
        errors = {
          ...errors,
          uc: "Upper comment C is missing",
        };

      if (!this.upperLimit?.cCommentIndex)
        errors = {
          ...errors,
          uc: "Upper comment C code is missing",
        };

      if (isEmptyValue(this.upperLimit?.cLimit))
        errors = {
          ...errors,
          uc: "Upper limit C value is missing",
        };

      if (!this.upperLimit?.bComment)
        errors = {
          ...errors,
          ub: "Upper comment B is missing",
        };

      if (!this.upperLimit?.bCommentIndex)
        errors = {
          ...errors,
          ub: "Upper comment B code is missing",
        };

      if (isEmptyValue(this.upperLimit?.bLimit))
        errors = {
          ...errors,
          ub: "Upper limit B value is missing",
        };

      if (!this.upperLimit?.aComment)
        errors = {
          ...errors,
          ua: "Upper comment A is missing",
        };

      if (!this.upperLimit?.aCommentIndex)
        errors = {
          ...errors,
          ua: "Upper comment A code is missing",
        };

      if (isEmptyValue(this.upperLimit?.aLimit))
        errors = {
          ...errors,
          ua: "Upper limit A value is missing",
        };

      if (isEmptyValue(this.upperLimit?.limitUnit)) {
        errors = {
          ...errors,
          limitUnit: "Limit unit value is missing",
        };
      }
    }

    // NOTE: rate of change is now Wear Rate
    if (
      !isEmptyValue(this.rateControl?.aRateOfChange) ||
      !isEmptyValue(this.rateControl?.bRateOfChange) ||
      !isEmptyValue(this.rateControl?.cRateOfChange)
    ) {
      if (isEmptyValue(this.rateControl?.aRateOfChange)) {
        errors = {
          ...errors,
          aRateOfChange: "Wear Rate (A) value is missing",
        };
      }

      if (isEmptyValue(this.rateControl?.bRateOfChange)) {
        errors = {
          ...errors,
          bRateOfChange: "Wear Rate (B) value is missing",
        };
      }

      if (isEmptyValue(this.rateControl?.cRateOfChange)) {
        errors = {
          ...errors,
          cRateOfChange: "Wear Rate (C) value is missing",
        };
      }
    }

    if (isEmptyValue(this.component)) {
      errors = {
        ...errors,
        component: "Please define Compartment / Component Type",
      };
    }

    if (isEmptyValue(this.resultCode)) {
      errors = {
        ...errors,
        resultCode: "Please define Result Code ",
      };
    }

    if (
      isEmptyValue(this.rateControl?.rateOfChangeUnit) &&
      !isEmptyValue(this.rateControl?.rateOfChangeValue)
    ) {
      errors = {
        ...errors,
        controlValues: "Please fill in Control Value and Control Value Unit",
      };
    }

    if (!(this.useLowerLimit() || this.useUpperLimit())) {
      errors = {
        ...errors,
        limitsRequired: "Please enter upper or lower limit values",
      };
    }

    // remarks

    if (isEmptyValue(this.remarks)) {
      errors = {
        ...errors,
        remarks: "Please enter remarks",
      };
    }

    return errors;
  },
};

const Rule = (state = initialState): IRule & IWithFaker =>
  pipe(
    withFaker({ id: faker.datatype.uuid() }),
    withConstructor(Rule)
  )({ ...initialState, ...state });

export { Rule as createRule };
