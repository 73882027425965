import faker from "faker";
import type IComment from "src/types/IComment";
import { withConstructor } from "./mixins/constructor";
import { IWithFaker, withFaker } from "./mixins/faker";
import { pipe } from "./mixins/pipe";

const initialState: IComment = {
  commentIndex: "",
  commentText: "",
};

const Comment = (state = initialState): IComment & IWithFaker =>
  pipe(
    withFaker({
      commentIndex: faker.datatype.number(),
      commentText: faker.lorem.text(),
    }),
    withConstructor(Comment)
  )({ ...initialState, ...state });

export { Comment as createComment };
