import React, { useEffect } from "react";
import type { FC } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import fetch from "src/services/fetch";
import { snackActions } from "src/utils/SnackbarUtils";
import { security } from "./Security";
import { config } from "src/config";

const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const NOT_FOUND = 404;
const TIMEOUT = 408;

interface AuthGuardProps {}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const {
    isAuthenticated,
    logout,
    isSessionValid,
    user: loggedInUser,
    shouldRedirect,
  } = useAuth();
  const { pathname, search } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  console.log("pathname", pathname.split("/").pop());
  console.log("pathname", pathname);
  console.log("params", params);

  useEffect(() => {
    // does user have access to URL
    const section = pathname
      .replace(config.APP_ROOT + "/", "")
      .replaceAll("/", ":");
    console.log("section", section);

    console.log("security check", security(loggedInUser, section));
    //navigate("")
  }, [pathname, loggedInUser]);

  useEffect(() => {
    fetch.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        try {
          const { status } = error?.response;

          if (status === UNAUTHORIZED) {
            logout();
            snackActions.error(
              "Your session has ended. To continue working please log back in."
            );
          }

          if (status === TIMEOUT) {
            snackActions.error(error?.response);
          }

          if (status === NOT_FOUND || status === BAD_REQUEST) {
            snackActions.error(error?.toString());
          }

          return Promise.reject(error);
        } catch (err) {
          logout();
          return Promise.reject(error);
        }
      }
    );
  }, [logout]);

  let redirectUrl = "";

  if (pathname) redirectUrl = pathname;

  if (search) redirectUrl += search;

  if (!isAuthenticated || !isSessionValid()) {
    navigate(
      redirectUrl === "" || !shouldRedirect
        ? "/login"
        : `/login?redirectUrl=${encodeURIComponent(redirectUrl)}`
    );
  }

  return <>{children}</>;
};

export default AuthGuard;
