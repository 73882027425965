import React, { useEffect, useState } from "react";
import type { FC } from "react";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useParameters } from "src/contexts/ParametersContext";
import AutocompleteParameter from "./AutocompleteParameter";
import { Info, Opacity } from "@material-ui/icons";
import { Mode } from "src/types/IForm";
import IRule from "src/types/IRule";
import useAuth from "src/hooks/useAuth";
import useDebounce from "src/hooks/useDebounce";

export interface ProgramProps {
  label?: string;
  value?: string;
}
export interface RuleParameterFormProps {
  [key: string]: any;
  mode?: Mode;
  rule?: IRule;
}

const RuleParameterForm: FC<RuleParameterFormProps> = ({
  mode = Mode.DEFAULT,
  rule,
}) => {
  const { state, selectOption, setParameterSearchTerm } = useParameters();
  const { user: loggedInUser } = useAuth();

  const [ruleId, setRuleId] = useState<string>(state.selections?.ruleId);
  const updatedRuleId = useDebounce(ruleId, 500);

  useEffect(() => {
    setRuleId(state.selections?.ruleId ?? "");
  }, [state]);

  useEffect(() => {
    selectOption({
      parameter: "ruleId",
      option: updatedRuleId,
    });
  }, [updatedRuleId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {[Mode.EDIT, Mode.READONLY].includes(mode) ? (
          <>
            <TextField
              disabled
              fullWidth
              label="Program"
              value={rule.programName}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Sub-Program"
              value={rule.subProgramName}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Customer/Client"
              value={rule.customerName}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Make"
              value={rule.make}
              variant="outlined"
              margin="dense"
            />
            <TextField
              disabled
              fullWidth
              label="Model"
              value={rule.model}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Oil Manufacturer"
              value={rule.oilManufacturer}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Oil Brand"
              value={rule.oilBrand}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Oil Grade"
              value={rule.oilGrade}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Component Type"
              value={rule.component}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Fuel Type"
              value={rule.fuelType}
              variant="outlined"
              margin="dense"
            />

            <TextField
              disabled
              fullWidth
              label="Result Code"
              value={rule.resultCode}
              variant="outlined"
              margin="dense"
            />
          </>
        ) : (
          <>
            {loggedInUser?.isExternal() ? (
              <TextField
                disabled
                fullWidth
                label="Program"
                value={loggedInUser.programName}
                variant="outlined"
                margin="dense"
              />
            ) : (
              <AutocompleteParameter
                state={state}
                selectOption={selectOption}
                // defaultValue={2}
                setParameterSearchTerm={setParameterSearchTerm}
                parameter="programs"
                label="Program"
              />
            )}

            {loggedInUser?.isExternal() && loggedInUser?.subProgramName ? (
              <TextField
                disabled
                fullWidth
                label="Sub-Program"
                value={loggedInUser.subProgramName}
                variant="outlined"
                margin="dense"
              />
            ) : (
              <AutocompleteParameter
                state={state}
                selectOption={selectOption}
                setParameterSearchTerm={setParameterSearchTerm}
                parameter="subPrograms"
                label="Sub-Program"
                disabled={!Boolean(state.selections.programs)}
              />
            )}

            <AutocompleteParameter
              state={state}
              selectOption={selectOption}
              setParameterSearchTerm={setParameterSearchTerm}
              parameter="clients"
              label="Customer/Client"
              disabled={!Boolean(state.selections.subPrograms)}
            />

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="makes"
              label="Make"
            />

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="models"
              label="Model"
              disabled={!Boolean(state.selections.makes)}
            />

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="oilManufacturers"
              label="Oil Manufacturer"
            />

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="oilBrands"
              label="Oil Brand"
              disabled={!Boolean(state.selections.oilManufacturers)}
            />

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="oilGrades"
              label="Oil Grade"
              disabled={!Boolean(state.selections.oilBrands)}
            />
            {state.selections?.oilId !== undefined &&
              state.selections?.oilId !== null &&
              state.selections?.oilId !== 0 && (
                <TextField
                  label="Oil Id"
                  value={state.selections?.oilId || ""}
                  id="filled-start-adornment"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Opacity />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {state?.isLoading["oilId"] && (
                          <CircularProgress color="inherit" size={20} />
                        )}

                        <InputAdornment position="end">
                          <Tooltip
                            title="READONLY: The Oil Id is calculated using Manufacturer, Grade and Brand options above."
                            aria-label="info"
                          >
                            <Info />
                          </Tooltip>
                        </InputAdornment>
                      </>
                    ),
                  }}
                  margin="dense"
                  fullWidth
                />
              )}

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="componentTypes"
              label="Compartment/Component Type"
            />

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="fuelTypes"
              label="Fuel Type"
            />

            <AutocompleteParameter
              state={state}
              setParameterSearchTerm={setParameterSearchTerm}
              selectOption={selectOption}
              parameter="resultCodes"
              label="Result Code"
            />

            {mode !== Mode.CREATE && (
              <AutocompleteParameter
                state={state}
                setParameterSearchTerm={setParameterSearchTerm}
                selectOption={selectOption}
                parameter="getRateChangeUnits"
                label="Control Value Unit"
              />
            )}

            {mode === Mode.DEFAULT && (
              <TextField
                variant="outlined"
                label="Rule ID"
                fullWidth
                id="rule-search"
                value={ruleId ?? ""}
                onChange={(event) => setRuleId(event.target.value)}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default RuleParameterForm;
