//@ts-nocheck
import { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import clsx from "clsx";
import {
  useLocation,
  matchPath,
  NavLink as RouterLink,
  useNavigate,
} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";

import Logo from "src/components/Logo";
import NavItem from "./NavItem";

import useAuth from "src/hooks/useAuth";
import Security from "src/components/Security";

import { useSections } from "./sections";

import { config } from "src/config";
interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  openDesktop: boolean;
  onDesktopNavToggle: () => void;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  exact?: boolean;
  access?: string;
  onClick?: () => {};
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <Security
        key={key}
        perform={item.access}
        yes={() => (
          <NavItem
            depth={depth}
            href={item?.href}
            access={item.access}
            icon={item.icon}
            info={item.info}
            open={Boolean(open)}
            title={item.title}
            exact={item.exact || false}
            onClick={item.onClick}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
            })}
          </NavItem>
        )}
      />
    );
  } else {
    acc.push(
      <Security
        key={key}
        perform={item.access}
        yes={() => (
          <NavItem
            depth={depth}
            href={item?.href}
            icon={item.icon}
            info={item.info}
            exact={item.exact || true}
            title={item.title}
            onClick={item.onClick}
          />
        )}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  fileUploadIcon: {
    height: 60,
    width: 60,
  },
  fileUploadContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #808080",
    borderRadius: "3px",
  },
  drawerOpen: {
    width: 256,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const NavBar: FC<NavBarProps> = ({
  onMobileClose,
  openMobile,
  openDesktop,
  onDesktopNavToggle,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const navigate = useNavigate();

  const sections = useSections(user, navigate);
  const [lastPath, setLastPath] = useState(location.pathname);

  useEffect(() => {
    if (openMobile && lastPath !== location.pathname) {
      if (onMobileClose) onMobileClose();
      setLastPath(location.pathname);
    }
  }, [location, onMobileClose, openMobile, lastPath]);

  const OpenDesktopContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp> */}
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink to={config.APP_HOME}>
            <Logo />
          </RouterLink>
        </Box>
        <Divider />

        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const ClosedDesktopContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Hidden lgUp> */}
        <Box p={2} display="flex" justifyContent="center">
          <Button
            fullWidth
            size="large"
            type="submit"
            component={RouterLink}
            to={config.APP_HOME}
          >
            <Logo width="100%" />
          </Button>
        </Box>
        <Divider />
        <Box p={0} textAlign="center">
          {sections.map((section) =>
            section.items.map(
              ({
                icon: Icon,
                href,
                title,
                access,
                onClick,
              }: {
                icon?: any;
                title?: string;
                href?: string;
                access?: string;
                onClick?: () => {};
              }) => {
                return (
                  <Security
                    key={title}
                    perform={access}
                    yes={() => (
                      <Tooltip title={<>{title}</>} placement="right">
                        <IconButton
                          activeClassName={classes.active}
                          component={RouterLink}
                          to={href}
                          onClick={onClick ? onClick : null}
                        >
                          {Icon && <Icon />}
                        </IconButton>
                      </Tooltip>
                    )}
                  />
                );
              }
            )
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {OpenDesktopContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="permanent"
          className={clsx({
            [classes.drawerOpen]: openDesktop,
            [classes.drawerClose]: !openDesktop,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: openDesktop,
              [classes.drawerClose]: !openDesktop,
            }),
          }}
        >
          {openDesktop && OpenDesktopContent}
          {!openDesktop && ClosedDesktopContent}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
