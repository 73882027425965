import React from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { config } from "src/config";

interface GuestGuardProps {}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, redirect } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated) {
    let url = config.APP_HOME;

    if (redirect) url = redirect;

    navigate(url);
  }

  return <>{children}</>;
};

export default GuestGuard;
