import { Role } from "src/types/IRole";

export interface IWithSecurity {
  isAdmin: () => boolean;
  isInternal: () => boolean;
    isInternalAdmin: () => boolean;
  isExternal: () => boolean;
  isExternalAdmin: () => boolean;
  isReadOnly: () => boolean;
  isFullAccess: () => boolean;
  _roleId: () => Role;
}

export const withSecurity = <T>(getRoleId: (state?: any) => number) => (
  o: T
): T & IWithSecurity => {
  return {
    ...o,
    _roleId() {
      if (typeof getRoleId === "function") return getRoleId(this);
      else throw new Error("missing function to get roleID");
    },
    isAdmin() {
      return (
        this._roleId() === Role.INTERNAL_USER_ADMIN ||
        this._roleId() === Role.EXTERNAL_USER_ADMIN
      );
    },
    isInternal() {
      return (
        this._roleId() === Role.INTERNAL_USER_ADMIN ||
        this._roleId() === Role.INTERNAL_USER_FULL_ACCESS ||
        this._roleId() === Role.INTERNAL_USER_READ_ONLY
      );
    },
        isInternalAdmin() {
      return (
        this.isAdmin() && this.isInternal()
      );
    },
    isExternal() {
      return (
        this._roleId() === Role.EXTERNAL_USER_ADMIN ||
        this._roleId() === Role.EXTERNAL_USER_FULL_ACCESS ||
        this._roleId() === Role.EXTERNAL_USER_READ_ONLY
      );
    },
            isExternalAdmin() {
      return (
        this.isAdmin() && this.isExternal()
      );
    },
    isFullAccess() {
      return (
        this._roleId() === Role.INTERNAL_USER_FULL_ACCESS ||
        this._roleId() === Role.EXTERNAL_USER_FULL_ACCESS
      );
    },
    isReadOnly() {
      return (
        this._roleId() === Role.INTERNAL_USER_READ_ONLY ||
        this._roleId() === Role.EXTERNAL_USER_READ_ONLY
      );
    },
  };
};
