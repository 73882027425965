import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

const TableHeaderContent = (props) => {
  const { column } = props;
  return column.name !== 'command' && column.name !== 'confidential' ? (
    <TableHeaderRow.Content {...props} />
  ) : null;
};

export default React.memo(TableHeaderContent);
