import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import fetch from "src/services/fetch";

import type { ReactNode } from "react";

const MIN_SEARCH_LENGTH: number = 0;

type ClearParameters = {
  type: "CLEAR_PARAMETERS";
};

type LoadingParameters = {
  type: "PARAMETERS_LOADING";
  payload: { parameter: string; status: boolean };
};

type SetParameterOptions = {
  type: "SET_PARAMETER_OPTIONS";
  payload: { parameter: string; options: any[] };
};

type SelectParameterOption = {
  type: "SELECT_PARAMETER_OPTION";
  payload: { parameter: string; option: any };
};

type SetParameterSearchTerm = {
  type: "SET_PARAMETER_SEARCH_TERM";
  payload: { parameter: string; searchTerm: string };
};

type UpdateConfig = {
  type: "UPDATE_CONFIG";
  payload: { config: any };
};

type SelectBulkOptions = {
  type: "SELECT_BULK_PARAMETER_OPTIONS";
  payload: { parameters: string[]; options: any[] };
};

type ResetState = {
  type: "RESET_STATE";
  payload: {
    initialState: ParametersState;
  };
};

type Action =
  | ClearParameters
  | LoadingParameters
  | SetParameterOptions
  | SelectParameterOption
  | SetParameterSearchTerm
  | UpdateConfig
  | SelectBulkOptions
  | ResetState;

interface ParametersProps {
  children: ReactNode;
}

interface ISelections {
  programs?: {
    name: string;
    id: number;
    program_Code: string;
  };
  subPrograms?: {
    name: string;
    id: number;
  };
  clients?: {
    name: string;
    id: number;
  };
  makes?: {
    name: string;
    id: number;
  };
  models?: {
    name: string;
    id: number;
  };
  componentTypes?: {
    name: string;
    id: number;
    key?: string;
  };
  fuelTypes?: {
    name: string;
    id: number;
  };
  oilManufacturers?: {
    name: string;
    id: number;
  };
  oilBrands?: {
    name: string;
    id: number;
  };
  oilGrades?: {
    name: string;
    id: number;
  };
  resultCodes?: {
    name: string;
    id: number;
    limitUnit?: string;
    testNo?: number;
    scalable?: string;
  };
  rateOfChangeUnits?: {
    unitCode: string;
    unitDisplay: string;
  };
  getRateChangeUnits?: {
    name: string;
    id: number;
  };
  oilId?: number;
  ruleId?: string;
}

export interface ParametersState {
  isFormDirty?: boolean;
  options?: any;
  selections?: ISelections;
  isLoading?: any;
  searchTerms?: any;
  config?: any;
}

export type Parameter =
  | "programs"
  | "subPrograms"
  | "clients"
  | "makes"
  | "models"
  | "componentTypes"
  | "fuelTypes"
  | "oilManufacturers"
  | "oilBrands"
  | "oilGrades"
  | "resultCodes"
  | "oilId"
  | "getRateChangeUnits"
  | "ruleId"
  | "";

interface ContextValue {
  state?: ParametersState;
  dispatch?: (value: Action) => void;
  clearParameters?: () => void;
  searchParam?: (params: any) => void;
  selectOption?: (params: any) => void;
  selectBulkOptions?: (params: any) => void;
  getSelectedParamValue?: (parameter: Parameter, defaultValue?: any) => any;
  setParameterSearchTerm?: (params: any) => void;
  init?: (config?: {
    loadPrograms?: boolean;
    loadSubPrograms?: boolean;
  }) => void;
  resetState?: () => void;
}

const isSearchTermValid = (
  term: string = "",
  minSearchLength = MIN_SEARCH_LENGTH
) => term && term !== "" && term.length >= minSearchLength;

const ParametersContext = createContext<ContextValue>({});
const useParameters = (): ContextValue => useContext(ParametersContext);

const reducer = (state: ParametersState, action: Action): ParametersState => {
  let newState = state;
  let modified = true;

  switch (action.type) {
    case "PARAMETERS_LOADING": {
      const { parameter, status } = action.payload;
      newState = {
        ...state,
        isLoading: { ...state.isLoading, [parameter]: status },
      };
      modified = false;
      break;
    }

    case "SET_PARAMETER_OPTIONS": {
      const { parameter, options } = action.payload;
      newState = {
        ...state,
        options: { ...state.options, [parameter]: options },
      };
      modified = false;
      break;
    }

    case "SELECT_PARAMETER_OPTION": {
      const { parameter, option } = action.payload;

      newState = {
        ...state,
        selections: { ...state.selections, [parameter]: option },
      };

      modified = false;
      break;
    }

    case "SELECT_BULK_PARAMETER_OPTIONS": {
      const { parameters, options } = action.payload;
      const bulkParams = parameters.reduce(
        (acc, val, i) => ({ ...acc, [val]: options[i] }),
        {}
      );

      newState = {
        ...state,
        selections: {
          ...state.selections,
          ...bulkParams,
        },
      };

      modified = false;
      break;
    }

    case "SET_PARAMETER_SEARCH_TERM": {
      const { parameter, searchTerm } = action.payload;
      newState = {
        ...state,
        searchTerms: { ...state.searchTerms, [parameter]: searchTerm },
      };
      modified = false;
      break;
    }

    case "CLEAR_PARAMETERS": {
      newState = {
        ...state,
        options: {},
        selections: {
          programs: state.selections.programs,
        },
      };
      modified = false;
      break;
    }

    case "UPDATE_CONFIG": {
      const { config } = action.payload;
      newState = { ...state, config };
      modified = false;
      break;
    }

    case "RESET_STATE": {
      const { initialState } = action.payload;
      newState = {
        ...initialState,
      };

      break;
    }
  }

  newState.isFormDirty = modified;
  return newState;
};

const ParametersProvider: React.FC<ParametersProps> = ({
  children,
}: ParametersProps) => {
  const initialParametersState: ParametersState = {
    isFormDirty: false,
    options: {},
    selections: {},
    isLoading: {},
    searchTerms: {},
    config: {},
  };

  const [state, dispatch] = useReducer(reducer, initialParametersState);

  const resetState = () => {
    dispatch({
      type: "RESET_STATE",
      payload: { initialState: initialParametersState },
    });
  };

  const getRateChange = useCallback(
    ({ parameter, value = "", config = { params: {} } }) => {
      if (state.isLoading[parameter]) {
        console.log("already searching API");
        return;
      }

      if (value !== "")
        config = {
          ...config,
          params: { ...config?.params, search: value },
        };

      dispatch({
        type: "PARAMETERS_LOADING",
        payload: { parameter, status: true },
      });

      fetch
        .get(`/Lookup/GetRateChangeUnits`, config)
        .then((response) => {
          setParameterOptions({
            parameter,
            options: response.data.map((u) => ({
              id: u.unitCode,
              name: u.unitDisplay,
            })),
          });

          dispatch({
            type: "PARAMETERS_LOADING",
            payload: { parameter, status: false },
          });
        })
        .catch((err) => {
          dispatch({
            type: "PARAMETERS_LOADING",
            payload: { parameter, status: false },
          });
        });
    },
    []
  );

  const searchParam = useCallback(
    ({ parameter, value = "", config = { params: {} } }) => {
      if (state.isLoading[parameter]) {
        console.log("already searching API");
        return;
      }

      if (value !== "")
        config = {
          ...config,
          params: { ...config?.params, search: value },
        };

      dispatch({
        type: "PARAMETERS_LOADING",
        payload: { parameter, status: true },
      });

      fetch
        .get(`/Lookup/${parameter}`, config)
        .then((response) => {
          setParameterOptions({ parameter, options: response.data.lookupList });

          dispatch({
            type: "PARAMETERS_LOADING",
            payload: { parameter, status: false },
          });
        })
        .catch((err) => {
          dispatch({
            type: "PARAMETERS_LOADING",
            payload: { parameter, status: false },
          });
        });
    },
    []
  );

  const init = (config?) => {
    config = {
      loadPrograms: true,
      loadSubPrograms: true,
      ...state.config,
      ...config,
    };

    dispatch({
      type: "UPDATE_CONFIG",
      payload: { config },
    });

    if (config.loadPrograms) searchParam({ parameter: "programs" });

    searchParam({ parameter: "componentTypes" }); //TODO: remove when search is fixed
    searchParam({ parameter: "fuelTypes" }); //TODO: remove when search is fixed
    searchParam({ parameter: "oilManufacturers" }); //TODO: remove when search is fixed
    searchParam({ parameter: "makes" }); //TODO: remove when search is fixed
    // searchParam({ parameter: "oilBrands" }); //TODO: remove when search is fixed
    // searchParam({ parameter: "oilGrades" }); //TODO: remove when search is fixed
    searchParam({
      parameter: "resultCodes",
    }); //TODO: remove when search is fixed
    getRateChange({ parameter: "getRateChangeUnits" });
  };

  const clearParameters = () => {
    dispatch({
      type: "CLEAR_PARAMETERS",
    });

    init(state.config);
  };

  const setParameterOptions = ({ parameter, options }) => {
    dispatch({
      type: "SET_PARAMETER_OPTIONS",
      payload: { parameter, options },
    });
  };

  const selectOption = ({ parameter, option }) => {
    dispatch({
      type: "SELECT_PARAMETER_OPTION",
      payload: { parameter, option },
    });
  };

  const selectBulkOptions = ({ parameters, options }) => {
    dispatch({
      type: "SELECT_BULK_PARAMETER_OPTIONS",
      payload: { parameters, options },
    });
  };

  const setParameterSearchTerm = ({ parameter, searchTerm }) => {
    dispatch({
      type: "SET_PARAMETER_SEARCH_TERM",
      payload: { parameter, searchTerm },
    });
  };

  // PROGRAMS
  useEffect(() => {
    // if there is only one option auto select it.
    if (
      state.options?.programs === undefined ||
      state.options?.programs.length === 0
    )
      return;

    if (state.options.programs.length === 1)
      selectOption({
        parameter: "programs",
        option: state.options.programs[0],
      });
  }, [state.options.programs]);

  //SUB PROGRAMS
  useEffect(() => {
    if (!state.config.loadSubPrograms) return;

    //clear sub program
    if (state.selections["subPrograms"])
      selectOption({
        parameter: "subPrograms",
        option: null,
      });

    if (!state.selections.programs) return;

    searchParam({
      parameter: "subPrograms",
      config: {
        params: {
          progID: state.selections.programs.id,
        },
      },
    });
  }, [state.selections.programs]);

  //CLIENTS
  useEffect(() => {
    if (!state.selections.programs) return;
    if (!state.selections.subPrograms) return;

    if (!isSearchTermValid(state.searchTerms.clients)) return;

    if (
      state.searchTerms.clients ===
      getSelectedParamValue("clients", { name: "" }).name
    )
      return;

    searchParam({
      parameter: "clients",
      value: state.searchTerms.clients,
      config: {
        params: {
          progID: state.selections?.programs?.id,
          subProgID: state.selections?.subPrograms?.id,
        },
      },
    });
  }, [state.searchTerms.clients]);

  //MAKES
  useEffect(() => {
    if (state.selections["models"])
      selectOption({
        parameter: "models",
        option: null,
      });

    if (!isSearchTermValid(state.searchTerms.makes)) return;

    if (
      state.searchTerms.makes ===
      getSelectedParamValue("makes", { name: "" }).name
    )
      return;

    searchParam({
      parameter: "makes",
      value: state.searchTerms.makes,
    });
  }, [state.searchTerms.makes]);

  //MODElS

  useEffect(() => {
    if (!isSearchTermValid(state.searchTerms.models)) return;

    if (!state.selections.makes) return;

    if (
      state.searchTerms.models ===
      getSelectedParamValue("models", { name: null }).name
    )
      return;

    searchParam({
      parameter: "models",
      value: state.searchTerms.models,
      config: {
        params: {
          makeID: state.selections.makes.id,
        },
      },
    });
  }, [state.searchTerms.models]);

  //COMPARTMENTS
  useEffect(() => {
    if (!isSearchTermValid(state.searchTerms.componentTypes)) return;

    if (
      state.searchTerms.componentTypes ===
      getSelectedParamValue("componentTypes", { name: null }).name
    )
      return;

    searchParam({
      parameter: "componentTypes",
      value: state.searchTerms.componentTypes,
    });
  }, [state.searchTerms.componentTypes]);

  //FUEL TYPES
  useEffect(() => {
    if (!isSearchTermValid(state.searchTerms.fuelTypes)) return;

    if (
      state.searchTerms.fuelTypes ===
      getSelectedParamValue("fuelTypes", { name: "" }).name
    )
      return;

    searchParam({
      parameter: "fuelTypes",
      value: state.searchTerms.fuelTypes,
    });
  }, [state.searchTerms.fuelTypes]);

  //OIL MANUFACTURERS
  useEffect(() => {
    if (!isSearchTermValid(state.searchTerms.oilManufacturers)) return;

    if (
      state.searchTerms.oilManufacturers ===
      getSelectedParamValue("oilManufacturers", { name: "" }).name
    )
      return;

    searchParam({
      parameter: "oilManufacturers",
      value: state.searchTerms.oilManufacturers,
    });
  }, [state.searchTerms.oilManufacturers]);

  //OIL BRANDS

  useEffect(() => {
    //clear oilBrands / oilGrades if manufactory is changed
    if (state.selections["oilBrands"])
      selectOption({
        parameter: "oilBrands",
        option: null,
      });

    if (state.selections["oilGrades"])
      selectOption({
        parameter: "oilGrades",
        option: null,
      });
  }, [state.selections.oilManufacturers]);

  // OIL BRANDS
  useEffect(() => {
    if (!state.selections.oilManufacturers) return;

    if (!isSearchTermValid(state.searchTerms.oilBrands)) return;

    if (
      state.searchTerms.oilBrands ===
      getSelectedParamValue("oilBrands", { name: "" }).name
    )
      return;

    searchParam({
      parameter: "oilBrands",
      value: state.searchTerms.oilBrands,
      config: {
        params: {
          manufacturer: state.selections.oilManufacturers.name,
        },
      },
    });
  }, [state.searchTerms.oilBrands]);

  //OIL GRADES
  useEffect(() => {
    if (!state.selections.oilBrands) return;
    if (!state.selections.oilManufacturers) return;

    if (!isSearchTermValid(state.searchTerms.oilGrades)) return;

    if (
      state.searchTerms.oilGrades ===
      getSelectedParamValue("oilGrades", { name: "" }).name
    )
      return;

    searchParam({
      parameter: "oilGrades",
      value: state.searchTerms.oilGrades,
      config: {
        params: {
          manufacturer: state.selections.oilManufacturers.name,
          brand: state.selections.oilBrands.name,
        },
      },
    });
  }, [state.searchTerms.oilGrades]);

  //RESULT CODES
  useEffect(() => {
    if (!isSearchTermValid(state.searchTerms.resultCodes)) return;

    if (
      state.searchTerms.resultCodes ===
      getSelectedParamValue("resultCodes", { name: "" }).name
    )
      return;

    searchParam({
      parameter: "resultCodes",
      value: state.searchTerms.resultCodes,
      config: {
        params: {},
      },
    });
  }, [state.searchTerms.resultCodes]);

  const getSelectedParamValue = (
    parameter: Parameter = "",
    defaultValue: any = null
  ) => {
    if (parameter === "") return defaultValue;

    return state.selections[parameter] || defaultValue;
  };

  //Oil ID
  useEffect(() => {
    // if all oil types are selected search for oil number

    if (state.selections["oilId"])
      selectOption({
        parameter: "oilId",
        option: null,
      });

    if (
      !state.selections?.oilManufacturers?.name ||
      !state.selections?.oilBrands?.name ||
      !state.selections?.oilGrades?.name
    )
      return;

    dispatch({
      type: "PARAMETERS_LOADING",
      payload: { parameter: "oilId", status: true },
    });

    fetch
      .get(`/Lookup/oilID`, {
        params: {
          manufacturer: state.selections?.oilManufacturers?.name,
          brand: state.selections?.oilBrands?.name,
          grade: state.selections?.oilGrades?.name,
        },
      })
      .then((response) => {
        selectOption({ parameter: "oilId", option: response.data });

        dispatch({
          type: "PARAMETERS_LOADING",
          payload: { parameter: "oilId", status: false },
        });
      })
      .catch((err) => {
        dispatch({
          type: "PARAMETERS_LOADING",
          payload: { parameter: "oilId", status: false },
        });
      });
  }, [
    state.selections.oilManufacturers,
    state.selections.oilBrands,
    state.selections.oilGrades,
  ]);

  const value = React.useMemo(
    () => ({
      state,
      clearParameters,
      searchParam,
      selectOption,
      getSelectedParamValue,
      setParameterSearchTerm,
      selectBulkOptions,
      init,
      dispatch,
      resetState,
    }),
    [
      state,
      clearParameters,
      getSelectedParamValue,
      searchParam,
      init,
      selectBulkOptions,
    ]
  );

  return (
    <ParametersContext.Provider value={value}>
      {children}
    </ParametersContext.Provider>
  );
};

export { ParametersProvider, useParameters };
