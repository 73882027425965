import React from "react";
import type { FC } from "react";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import type { Theme } from "src/theme";
import Account from "./Account";
import Settings from "./Settings";
import Logout from "./Logout";
interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  onDesktopNavToggle?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    zIndex: theme.zIndex.drawer + 100,
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  onDesktopNavToggle,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <IconButton
            color="inherit"
            onClick={onDesktopNavToggle}
            style={{ position: "relative", left: "-16px" }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Logout />
        {process.env.NODE_ENV !== "production" && <Settings />}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
