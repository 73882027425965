import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import routes from "src/routes";
import { useApp } from "./contexts/AppContext";
import Emitter from "./services/emitter";

const App = () => {
  const content = useRoutes(routes);

  const { swInit, swUpdate } = useApp();

  useEffect(() => {
    Emitter.on("SERVER_WORKER", handleSWEvent, App);
    Emitter.on("SERVER_WORKER", handleSWEvent, App);

    return () => {
      Emitter.off("SERVER_WORKER", handleSWEvent, App);
      Emitter.off("SERVER_WORKER", handleSWEvent, App);
    };
  }, []);

  const handleSWEvent = (payload) => {
    const { action, registeration } = payload;
    console.log("action", action);
    console.log("registeration", registeration);
    switch (action) {
      case "SW_INIT":
        swInit();
        break;
      case "SW_UPDATE":
        swUpdate(registeration);
        break;
    }
  };

  return <>{content}</>;
};

export default App;
