import fetch from "src/services/fetch";
import { IUser } from "src/types/IUser";
import urlBuilder from "src/utils/urlBuilder";
import * as factory from "src/factory/user";

interface IUserQuery {
  pageNumber: number;
  pagination: true;
  result: IUser[];
  rowsPerPage: number;
  totalPages: number;
  totalRecords: number;
  userId: number;
}
interface IUserAuthenicationResponse extends IUser {
  userId: number;
  roleId: number;
}

export const authenicate = async (email, password) => {
  const response = await fetch.post<IUserAuthenicationResponse>(
    urlBuilder("User/Authenticate"),
    { email, password }
  );

  const { userId, roleId, ...rest } = response.data;

  return factory.createUser({
    ...rest,
    userID: userId,
    roleID: roleId,
  });
};

export const getUsersByDynamicQuery = async (query: any, limit?: number) => {
  if (limit) {
    query = {
      ...query,
      rowsPerPage: limit,
      pagination: true,
      totalPages: 1,
    };
  }
  const response = await fetch.post<IUserQuery>(
    urlBuilder("User/GetUsersByDynamicQuery"),
    query
  );

  let users: IUser[] = response.data.result.map((x) => factory.createUser(x));

  return users;
};

export const getMyAccountInfo = async (): Promise<IUser> => {
  const response = await fetch.get<IUser>(urlBuilder("User/GetMyAccountInfo"));
  return factory.createUser(response.data);
};

export const getUsersByQueryRaw = async (query: any) => {
  return await fetch.post<IUserQuery>(
    urlBuilder("User/GetUsersByDynamicQuery"),
    query
  );
};

export const getUserByUserId = async (userId: number) => {
  const users = await getUsersByDynamicQuery({
    // where: queryHelper.getUser(userId).getQuery()
  });

  return users[0] || null;
};

export const createUser = async (user: IUser) => {
  const response = await fetch.post<IUser>(urlBuilder("User/AddUser"), user);
  return response.data;
};

export const updateUser = async (user: IUser) => {
  const response = await fetch.post<IUser>(
    urlBuilder("User/UpdateUser"),
    user,
    {
      params: {
        id: user.userID,
      },
    }
  );

  return response.data;
};

export const deleteUser = async (user: IUser) => {
  return await fetch.post<IUser>(urlBuilder("User/DeleteUser"), null, {
    params: {
      id: user.userID,
    },
  });
};

export const changePassword = async (password: string, user: IUser) => {
  return await fetch.post<IUser>(
    urlBuilder("User/ResetPassword"),
    {
      password,
      confirmPassword: password,
    },
    {
      params: {
        id: user.userID,
      },
    }
  );
};

export const updateMyAccountInfo = async (user: IUser) => {
  const response = await fetch.post<IUser>(
    urlBuilder("User/UpdateMyAccountInfo"),
    user,
    {
      params: {
        id: user.userID,
      },
    }
  );

  return factory.createUser(response.data);
};
