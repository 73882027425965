import { useState, ReactNode, useEffect } from "react";
import type { FC } from "react";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { Theme } from "src/theme";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Outlet } from "react-router";
import Emitter from "src/services/emitter";
import { Section, Status, useApp } from "src/contexts/AppContext";
import AuditLog from "src/pages/audit/AuditLog";
import RuleForm from "src/pages/home/RuleForm";
import { useRules } from "src/contexts/RulesContext";
import { AuditsProvider } from "src/contexts/AuditContext";
import { useParameters } from "src/contexts/ParametersContext";
import { SamplesProvider } from "src/contexts/SamplesContext";
import { Mode } from "src/types/IForm";
import Loader from "src/components/Loader";
import AppLoader from "src/components/AppLoader";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [isDesktopNavOpen, setDesktopNavToggle] = useState<boolean>(false);

  const { openSection, closeSection, state: appState } = useApp();
  const { clearParameters, resetState: resetParametersState } = useParameters();
  const {
    createRule,
    setCurrentRule,
    resetState: resetRulesState,
  } = useRules();

  const handleUserEvent = (payload) => {
    const { action } = payload;
    switch (action) {
      case "LOGOUT":
        resetParametersState();
        resetRulesState();
        break;
    }
  };

  const handleParametersEvent = (payload) => {
    const { action } = payload;
    switch (action) {
      case "CLEAR_PARAMETERS":
        clearParameters();
        break;
    }
  };

  const handleAuditEvent = (payload) => {
    const { action } = payload;
    switch (action) {
      case "OPEN":
        const { rule } = payload;
        openSection(Section.AUDIT, { rule });
        break;
    }
  };

  const handleRuleEvent = (payload) => {
    const { action, rule } = payload;
    switch (action) {
      case "EDIT":
        setCurrentRule(rule);
        openSection(Section.RULE, { rule, mode: Mode.EDIT });
        break;

      case "OPEN":
        setCurrentRule(rule);
        openSection(Section.RULE, { rule, mode: Mode.READONLY });
        break;

      case "CREATE_NEW":
        createRule();
        openSection(Section.RULE);
        break;

      case "COPY":
        setCurrentRule(rule);
        openSection(Section.RULE, { rule, mode: Mode.CREATE });
        break;
    }
  };

  useEffect(() => {
    Emitter.on("AUDIT_LOG", handleAuditEvent, DashboardLayout);
    Emitter.on("RULE", handleRuleEvent, DashboardLayout);
    Emitter.on("PARAMETERS", handleParametersEvent, DashboardLayout);
    Emitter.on("USER", handleUserEvent, DashboardLayout);

    return () => {
      Emitter.off("AUDIT_LOG", handleAuditEvent, DashboardLayout);
      Emitter.off("RULE", handleRuleEvent, DashboardLayout);
      Emitter.off("PARAMETERS", handleParametersEvent, DashboardLayout);
      Emitter.off("USER", handleUserEvent, DashboardLayout);
    };
  }, [clearParameters, resetParametersState, resetParametersState]);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onDesktopNavToggle={() => setDesktopNavToggle((prev) => !prev)}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        openDesktop={isDesktopNavOpen}
        onDesktopNavToggle={() => setDesktopNavToggle((prev) => !prev)}
      />

      <AuditsProvider>
        <SamplesProvider>
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>{children || <Outlet />}</div>
            </div>
          </div>

          {appState.section[Section.RULE].status === Status.OPEN && (
            <Backdrop
              className={classes.backdrop}
              open={appState.section[Section.RULE].status === Status.OPEN}
              onClick={null}
            >
              <Card
                onClick={(e) => e.stopPropagation()}
                style={{
                  width: "70%",
                  height: "83vh",
                  overflow: "auto",
                }}
              >
                <CardContent>
                  <RuleForm
                    mode={appState.section[Section.RULE].metaData?.mode}
                    onClose={() => closeSection(Section.RULE)}
                  />
                </CardContent>
              </Card>
            </Backdrop>
          )}

          {appState.section[Section.AUDIT].status === Status.OPEN && (
            <Backdrop
              className={classes.backdrop}
              open={appState.section[Section.AUDIT].status === Status.OPEN}
              onClick={null}
            >
              <Card
                onClick={(e) => e.stopPropagation()}
                style={{
                  maxWidth: "90%",
                  maxHeight: "85%",
                  overflow: "auto",
                }}
              >
                <CardContent>
                  <AuditLog
                    rule={appState.section[Section.AUDIT].metaData?.rule}
                    onClose={() => closeSection(Section.AUDIT)}
                  />
                </CardContent>
              </Card>
            </Backdrop>
          )}

          <AppLoader />
        </SamplesProvider>
      </AuditsProvider>
    </div>
  );
};

export default DashboardLayout;
