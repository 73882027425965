const columns = [
  {
    name: "action",
    title: "Action",
    width: 125,
    default: true,
  },
  {
    name: "commentIndex",
    title: "Comment Code",
    default: true,
    width: 150,
  },
  {
    name: "commentText",
    title: "Comment Description",
    default: true,
    width: 450,
  },
];

export default columns;
