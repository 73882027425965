import React from "react";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";

import {
  DeleteForever as DeleteForeverIcon,
  Edit,
  ListAlt,
  OpenInNew as OpenInNewIcon,
  RemoveCircleOutline,
} from "@material-ui/icons";
import type { Theme } from "src/theme";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.colors.neutralGrey,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& > * ": {
      cursor: "pointer",
      padding: 0,
    },
  },
  link: {
    color: theme.palette.colors.neutralGrey,
  },
}));

const CommandsCell = ({ row, onDelete, onOpen, onAudit, onEdit }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {onDelete && (
        <Tooltip title="Remove">
          <IconButton onClick={() => onDelete(row)}>
            <RemoveCircleOutline />
          </IconButton>
        </Tooltip>
      )}
      {onOpen && (
        <Tooltip title="Open">
          <IconButton onClick={() => onOpen(row)}>
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      )}
      {onAudit && (
        <Tooltip title="Audit Log">
          <IconButton onClick={() => onAudit(row)}>
            <ListAlt />
          </IconButton>
        </Tooltip>
      )}
      {onEdit && (
        <Tooltip title="Edit">
          <IconButton onClick={() => onEdit(row)}>
            <Edit />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default CommandsCell;
