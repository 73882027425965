import faker from "faker";
import type ISample from "src/types/ISample";
import { withConstructor } from "./mixins/constructor";
import { IWithFaker, withFaker } from "./mixins/faker";
import { pipe } from "./mixins/pipe";

const initialState: Partial<ISample> = {
  sampleNo: "",
  labNo: "",
  sampledDate: "",
  severity: "",
  condition: "",
  customer: "",
  component: "",
  makeAndModel: "",
  compTime: 0,
  oilTime: 0,
  timeUOM: "",
  result: "",
  resFlag: "",
  deserialize: function (sample: ISample) {
    // remove all non-numeric characters from the results value
    sample.result = sample.result.replace(/[^\d.-]/g, "");

    Object.getOwnPropertyNames(this).forEach((prop) => {
      if (sample[prop] !== undefined) this[prop] = sample[prop];
    });

    return this as ISample;
  },
};

const Sample = (state = initialState): ISample & IWithFaker =>
  pipe(
    withFaker({
      sampleNo: faker.datatype.number(),
      labNo: faker.datatype.number(),
      sampleDate: faker.date.past().toString(),
      severity: faker.random.arrayElement(["A", "B", "C"]),
      condition: faker.lorem.text(),
      customer: faker.lorem.text(),
      component: faker.lorem.text(),
      makeAndModel: faker.lorem.text(),
      compTime: faker.datatype.number({ min: 1, max: 500 }),
      oilTime: faker.datatype.number({ min: 1, max: 500 }),
      timeUOM: faker.lorem.text(),
      result: faker.finance.amount(100, 1000, 4),
      resFlag: "",
    }),
    withConstructor(Sample)
  )({ ...initialState, ...state });

export { Sample as createSample };
