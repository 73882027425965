import { Role } from "src/types/IRole";

const generalAccess = ["dashboard", "audit"];

const fullAccess = [...generalAccess, "users", "users:browse"];

const isMe = ({ userId, currentUserId }) => {
  if (!userId || !currentUserId) return false;
  return userId === currentUserId;
};

const rules = {
  [Role.INTERNAL_USER_ADMIN]: {
    static: [
      ...fullAccess,
      "users:password:change",
      "users:user_type:edit",
      "users:create",
      "users/create",
      "users:delete",
      "users:edit",
      "users:create:external_user_read_only",
      "users:create:external_user_full_access",
      "users:create:external_user_admin",
      "users:create:internal_user_read_only",
      "users:create:internal_user_full_access",
      "users:create:internal_user_admin",
      "rules:create",
      "rules:edit",
      "rules:delete",
      "comments:edit",
      "audit",
    ],
  },
  [Role.INTERNAL_USER_FULL_ACCESS]: {
    static: [...fullAccess],
    dynamic: {
      "users:password:change": isMe,
      "users:edit": isMe,
    },
  },
  [Role.INTERNAL_USER_READ_ONLY]: {
    static: [...generalAccess, "users", "users:browse"],
    dynamic: {
      "users:password:change": isMe,
      "users:edit": isMe,
    },
  },
  [Role.EXTERNAL_USER_ADMIN]: {
    static: [
      ...generalAccess,
      "users:client:name:edit",
      "users",
      "users:browse",
      "users:create",
      "users:delete",
      "users:edit",
      "users:password:change",
      "users:create:external_user_read_only",
      "users:create:external_user_full_access",
      "users:create:external_user_admin",
      "rules:create",
      "rules:edit",
      "rules:delete",
      "comments:edit",
    ],
    dynamic: {
      "users:password:change": isMe,
      "users:edit": isMe,
    },
  },

  [Role.EXTERNAL_USER_FULL_ACCESS]: {
    static: [...generalAccess],
    dynamic: {
      "users:password:change": isMe,
      "users:edit": isMe,
    },
  },

  [Role.EXTERNAL_USER_READ_ONLY]: {
    static: [...generalAccess],

    dynamic: {
      "users:password:change": isMe,
      "users:edit": isMe,
    },
  },
};

export default rules;
