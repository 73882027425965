import React, { useCallback, useMemo } from "react";
import type { FC } from "react";

import {
  Grid,
  TableHeaderRow,
  TableColumnResizing,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";

import Loader, { LoaderType } from "../Loader";

import Cell from "./@components/cells/Cell";

interface Props {
  [key: string]: any;
  rows?: any[];
  totalCount?: number;
  pageSize?: number;
  currentPage?: number;
  isLoading?: boolean;
}

const SimpleTable: FC<Props> = ({
  columns = [],
  rows = [],
  isLoading = false,
  onCommentCodeChange,
  ...rest
}) => {
  const columnWidths = useMemo(
    () =>
      columns.map((column, index) => ({
        columnName: column.name,
        width: column?.width ?? 150,
      })),
    [columns]
  );

  const TableComponent = useCallback(
    (props) => {
      return isLoading ? (
        <VirtualTable.Table {...props}>
          <Loader loaderType={LoaderType.RING} />
        </VirtualTable.Table>
      ) : (
        <VirtualTable.Table {...props} />
      );
    },
    [isLoading]
  );

  const CustomCells = (props) => {
    return <Cell {...props} onCommentCodeChange={onCommentCodeChange} />;
  };

  return (
    <Grid rows={rows} columns={columns} {...rest}>
      <VirtualTable
        height={300}
        cellComponent={CustomCells}
        tableComponent={TableComponent}
        columnExtensions={[
          { columnName: "commentText", wordWrapEnabled: true },
        ]}
      />
      <TableColumnResizing defaultColumnWidths={columnWidths} />
      <TableHeaderRow />
    </Grid>
  );
};

export default SimpleTable;
