import { useState } from "react";
import { isEqual } from "src/utils/object";

export interface IPager {
  offset: number;
  limit: number;
  pageSizes: number[];
}

export const usePagination = () => {
  const pageSizes = [25, 50, 75];
  const defaultPageSize = pageSizes[2];
  const [state, setState] = useState<IPager>({
    offset: 0,
    limit: defaultPageSize,
    pageSizes,
  });
  const [lastState, setLastState] = useState<IPager>({
    offset: 0,
    limit: defaultPageSize,
    pageSizes,
  });

  const setOffset = (offset): void => {
    setState((prev) => {
      setLastState(prev);

      return { ...prev, offset };
    });
  };

  const setSize = (limit: number): void => {
    setState((prev) => {
      setLastState(prev);
      return {
        ...prev,
        limit,
        offset: 0,
      };
    });
  };

  const isDifferent = () => {
    return !isEqual(state, lastState);
  };

  return { state, setOffset, setSize, isDifferent };
};
