import faker from "faker";
import { Role } from "src/types/IRole";
import { IUser } from "src/types/IUser";
import { removeEmpty } from "src/utils/object";
import { capitalize, getInitials } from "src/utils/string";
import { withConstructor } from "./mixins/constructor";
import { IWithFaker, withFaker } from "./mixins/faker";
import { pipe } from "./mixins/pipe";
import { IWithSecurity, withSecurity } from "./mixins/security";

const initialState: Partial<IUser> = {
  userID: null,
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  notes: "",
  userType: "",
  active: true,
  programID: null,
  programName: "",
  subProgramID: null,
  subProgramName: "",
  password: null,
  passwordActiveDays: null,
  roleID: Role.EXTERNAL_USER_READ_ONLY,
  createdBy: 0,
  createdByName: "",
  createdDate: "",
  modifiedBy: 0,
  modifiedByName: "",
  modifiedDate: "",

  getFullName: function () {
    return capitalize(`${this.firstName} ${this.lastName}`, true);
  },
  getInitials: function () {
    return getInitials(this.getFullName());
  },
  serialize: function () {
    return removeEmpty(
      Object.getOwnPropertyNames(this).reduce((acc, prop) => {
        return { ...acc, [prop]: this[prop] };
      }, {})
    );
  },
  deserialize: function (user: IUser) {
    Object.getOwnPropertyNames(this).forEach((prop) => {
      if (user[prop] !== undefined) this[prop] = user[prop];
    });

    return this as IUser;
  },
};

const User = (
  state: Partial<IUser> = initialState
): IUser & IWithSecurity & IWithFaker =>
  pipe(
    withFaker({
      id: faker.datatype.uuid(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      email: faker.internet.email(),
      phone: faker.phone.phoneNumber(),
      notes: faker.lorem.paragraph(),
      roleID: Role.EXTERNAL_USER_READ_ONLY,
    }),
    withSecurity<IUser>(({ roleID }) => roleID),
    withConstructor(User)
  )({ ...initialState, ...state });

export { User as createUser };
