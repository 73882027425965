import React, { useRef, useState } from "react";
import type { FC } from "react";
import { FormikProps } from "formik";
import Header from "src/components/Header";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import LimitForm from "src/components/LimitForm";
import RuleParameterForm from "src/components/RuleParameterForm";
import { useRules } from "src/contexts/RulesContext";
import { useParameters } from "src/contexts/ParametersContext";
import RangeSlider from "src/components/RangeSlider";
import { Theme } from "src/theme";
import { Mode } from "src/types/IForm";
import { submitError, submitSuccess } from "src/components/Forms/FormikHelper";
import IRule from "src/types/IRule";
import Emitter from "src/services/emitter";
import TextField from "src/components/Forms/TextField";
import { useEffect } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Security from "src/components/Security";
import GraphComponent from "./Graph";
import { useSamples } from "src/contexts/SamplesContext";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  aText: { color: theme.palette.colors.limit.la },
  bText: { color: theme.palette.colors.limit.lb },
  cText: { color: theme.palette.colors.limit.lc },
}));

interface RuleFormProps {
  onClose?: (event?) => void;
  mode?: Mode;
}

const RuleForm: FC<RuleFormProps> = ({ onClose, mode = Mode.CREATE }) => {
  const classes = useStyles();
  const [ruleSummaryVisible, setRuleSummaryVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { state: ruleState, saveRule, updateRule, setRule } = useRules();
  const { getSamples, state: sampleState } = useSamples();

  const { state: paramState } = useParameters();

  const formRef = useRef<FormikProps<any>>();

  useEffect(() => {
    const { programs, resultCodes, componentTypes } = paramState.selections;

    if ([Mode.READONLY, Mode.EDIT].includes(mode)) return;

    if (programs && resultCodes && componentTypes) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [paramState.selections]);

  useEffect(() => {
    const {
      resultCodes,
      componentTypes,
      getRateChangeUnits,
    } = paramState.selections;

    const selectedResultCode = paramState.selections["resultCodes"];

    let newRule = {
      ...ruleState.currentRule,
      rateControl: {
        ...ruleState.currentRule.rateControl,
        rateOfChangeUnit: getRateChangeUnits?.id,
      },
    };

    if (selectedResultCode) {
      // update the limit unit on rule

      const { limitUnit, scalable } = selectedResultCode;

      newRule = {
        ...newRule,
        scalable,
        lowerLimit: {
          ...newRule.lowerLimit,
          limitUnit,
        },
        upperLimit: {
          ...newRule.upperLimit,
          limitUnit,
        },
      };
    }

    if (mode === Mode.CREATE) {
      newRule = {
        ...newRule,
        component: componentTypes?.name,
        resultCode: resultCodes?.name,
      };
    }

    setRule(newRule);
  }, [paramState.selections, mode]);

  const handleSaveClick = () => {
    saveRule(paramState)
      .then((response) => {
        submitSuccess(`Rule Successfully Created. Rule ID # ${response.data}`);
        Emitter.emit("RULE", { action: "CREATED_NEW" });
        if (onClose) onClose();
      })
      .catch((e) => submitError(e));
  };

  const handleUpdateClick = () => {
    updateRule()
      .then((response) => {
        submitSuccess(`Rule Successfully Updated`);
        Emitter.emit("RULE", { action: "SAVED" });
        if (onClose) onClose();
      })
      .catch((e) => submitError(e));
  };

  const handleNextClick = async () => {
    setRuleSummaryVisible(!ruleSummaryVisible);
  };

  const handleCopyRule = (event, rule: IRule) => {
    Emitter.emit("RULE", { action: "COPY", rule: rule.copy(paramState) });
  };

  const { lowerLimit, upperLimit, rateControl } = ruleState.currentRule;

  const getTitle = (mode: Mode, rule: IRule) => {
    switch (mode) {
      case Mode.CREATE:
        return "Create Rule";
      case Mode.EDIT:
        return `Edit Rule # ${rule.ruleUniqueID}`;
      case Mode.READONLY:
        return `Rule # ${rule.ruleUniqueID}`;
    }
  };

  const loadSamples = () => {
    const { currentRule } = ruleState;

    getSamples({
      params: {
        testNo: currentRule.testNo,
        ruleId: currentRule.ruleID,
        progId: currentRule.progID,
        ruleUniqueID: currentRule.ruleUniqueID,
        RateOfChangeUnit: currentRule?.rateControl?.rateOfChangeUnit,
      },
    });
  };

  const handleLoadSamplesClick = () => {
    loadSamples();
  };

  const hasSampleData =
    sampleState.cachedSamples[ruleState.currentRule.ruleUniqueID];

  return (
    <>
      {!ruleSummaryVisible ? (
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Header title={getTitle(mode, ruleState.currentRule)} />
            <RuleParameterForm mode={mode} rule={ruleState.currentRule} />
          </Grid>
          <Grid item xs={9} style={{ borderLeft: "solid lightgrey 1px" }}>
            <LimitForm
              innerRef={formRef}
              onSubmit={handleSaveClick}
              isDisabled={isDisabled || mode === Mode.READONLY}
              mode={mode}
            />
            <Grid container spacing={2} justify="flex-end">
              <Box p={2} flex="1">
                {[Mode.READONLY].includes(mode) && (
                  <Security
                    perform="rules:create"
                    yes={() => (
                      <Button
                        variant="contained"
                        onClick={(event) =>
                          handleCopyRule(event, ruleState.currentRule)
                        }
                        startIcon={<FileCopyIcon />}
                      >
                        Copy Rule # {ruleState.currentRule?.ruleUniqueID}
                      </Button>
                    )}
                  />
                )}
              </Box>
              <Box p={2}>
                <Button variant="text" onClick={onClose}>
                  Cancel
                </Button>
              </Box>
              {mode !== Mode.READONLY && (
                <Box p={2}>
                  <Button
                    disabled={
                      !ruleState.isDirty || ruleState.currentRule.isValid()
                    }
                    color="primary"
                    variant="contained"
                    onClick={handleNextClick}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Rule Summary</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <Typography variant="h4">Parameters</Typography>
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Program"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.programName
                    : paramState.selections["programs"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Sub Program"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.subProgramName
                    : paramState.selections["subPrograms"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Customer/Client"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.customerName
                    : paramState.selections["clients"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Make"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.make
                    : paramState.selections["makes"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Mode"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.model
                    : paramState.selections["models"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Oil Manufacturer"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.oilManufacturer
                    : paramState.selections["oilManufacturers"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Oil Brand"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.oilBrand
                    : paramState.selections["oilBrands"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Oil Grade"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.oilGrade
                    : paramState.selections["oilGrades"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Compartment/Component Type"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.component
                    : paramState.selections["componentTypes"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Fuel Type"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.fuelType
                    : paramState.selections["fuelTypes"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>

            <Box py={1}>
              <TextField
                readonly
                label="Result Code"
                value={
                  [Mode.EDIT, Mode.READONLY].includes(mode)
                    ? ruleState.currentRule.resultCode
                    : paramState.selections["resultCodes"]?.name
                }
                variant="outlined"
                bottomGutter
              />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box py={1}>
              <Typography variant="h4">Results and Limits</Typography>
            </Box>
            <Box p={2}>
              <RangeSlider
                useUpperLimit={ruleState.currentRule.useUpperLimit()}
                useLowerLimit={ruleState.currentRule.useLowerLimit()}
                {...ruleState.currentRule.getMarginOffsets()}
                marks={ruleState.currentRule.generateMarks()}
                value={ruleState.currentRule.getLimits()}
              />
            </Box>
            {rateControl.rateOfChangeUnit && (
              <Box py={1}>
                <TextField
                  readonly
                  stacked={false}
                  label="Control Value"
                  value={rateControl.rateOfChangeValue}
                  variant="outlined"
                />
                <br />
                <TextField
                  readonly
                  stacked={false}
                  label="Control Unit"
                  value={rateControl.rateOfChangeUnit}
                  variant="outlined"
                />
                {rateControl?.aRateOfChange && (
                  <>
                    <br />
                    <TextField
                      readonly
                      stacked={false}
                      label="Wear Rate (A)"
                      value={rateControl?.aRateOfChange}
                      variant="outlined"
                      bottomGutter
                    />
                  </>
                )}

                {rateControl?.bRateOfChange && (
                  <>
                    <br />
                    <TextField
                      readonly
                      stacked={false}
                      label="Wear Rate (B)"
                      value={rateControl.bRateOfChange}
                      variant="outlined"
                      bottomGutter
                    />
                  </>
                )}
                {rateControl?.cRateOfChange && (
                  <>
                    <br />
                    <TextField
                      readonly
                      stacked={false}
                      label="Wear Rate (C)"
                      value={rateControl.cRateOfChange}
                      variant="outlined"
                      bottomGutter
                    />
                  </>
                )}
              </Box>
            )}
            {ruleState.currentRule.useLowerLimit() && (
              <>
                <Box py={1}>
                  <Typography variant="body2" className={classes.cText}>
                    LC Unit - {lowerLimit.cLimit} {lowerLimit.limitUnit}
                  </Typography>
                  <Typography variant="body2">
                    Comment Code - ({lowerLimit.cCommentIndex})
                  </Typography>
                  <Typography variant="body1">{lowerLimit.cComment}</Typography>
                </Box>
                <Box py={1}>
                  <Typography variant="body2" className={classes.bText}>
                    LB Unit - {lowerLimit.bLimit} {lowerLimit.limitUnit}
                  </Typography>
                  <Typography variant="body2">
                    Comment Code - ({lowerLimit.bCommentIndex})
                  </Typography>
                  <Typography variant="body1">{lowerLimit.bComment}</Typography>
                </Box>
                <Box py={1}>
                  <Typography variant="body2" className={classes.aText}>
                    LA Unit - {lowerLimit.aLimit} {lowerLimit.limitUnit}
                  </Typography>
                  <Typography variant="body2">
                    Comment Code - ({lowerLimit.aCommentIndex})
                  </Typography>
                  <Typography variant="body1">{lowerLimit.aComment}</Typography>
                </Box>
              </>
            )}
            {ruleState.currentRule.useUpperLimit() && (
              <>
                <Box py={1}>
                  <Typography variant="body2" className={classes.aText}>
                    UA Unit - {upperLimit.aLimit} {upperLimit.limitUnit}
                  </Typography>
                  <Typography variant="body2">
                    Comment Code - ({upperLimit.aCommentIndex})
                  </Typography>
                  <Typography variant="body1">{upperLimit.aComment}</Typography>
                </Box>
                <Box py={1}>
                  <Typography variant="body2" className={classes.bText}>
                    UB Unit - {upperLimit.bLimit} {upperLimit.limitUnit}
                  </Typography>
                  <Typography variant="body2">
                    Comment Code - ({upperLimit.bCommentIndex})
                  </Typography>
                  <Typography variant="body1">{upperLimit.bComment}</Typography>
                </Box>
                <Box py={1}>
                  <Typography variant="body2" className={classes.cText}>
                    UC Unit - {upperLimit.cLimit} {upperLimit.limitUnit}
                  </Typography>
                  <Typography variant="body2">
                    Comment Code - ({upperLimit.cCommentIndex})
                  </Typography>
                  <Typography variant="body1">{upperLimit.cComment}</Typography>
                </Box>
              </>
            )}

            {ruleState.currentRule?.remarks && (
              <Box py={1}>
                <Typography variant="body2">Remarks</Typography>
                <Typography variant="body2">
                  {ruleState.currentRule?.remarks}
                </Typography>
              </Box>
            )}

            <Grid container spacing={2} justify="flex-end">
              <Box p={2}>
                <Button
                  variant="text"
                  onClick={(event) => {
                    if (onClose) onClose(event);
                    setRuleSummaryVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box p={2}>
                <Button
                  variant="text"
                  onClick={() => {
                    setRuleSummaryVisible(!ruleSummaryVisible);
                  }}
                >
                  Edit
                </Button>
              </Box>
              <Box p={2}>
                {mode === Mode.CREATE ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleUpdateClick}
                  >
                    Update
                  </Button>
                )}
              </Box>
            </Grid>

            {mode === Mode.EDIT && (
              <Grid item>
                {!hasSampleData && (
                  <Box py={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleLoadSamplesClick}
                    >
                      Generate Results
                    </Button>
                  </Box>
                )}
                {hasSampleData && (
                  <Box>
                    <GraphComponent
                      isLoading={sampleState.isLoading}
                      chartData={
                        ruleState.currentRule.getGraphData(
                          sampleState.samples
                        ) ?? []
                      }
                      modifyDomain={[
                        ruleState.currentRule.getMarginOffsets().min,
                        ruleState.currentRule.getMarginOffsets().max,
                      ]}
                      graphStyles={[
                        {
                          label: "Scatter",
                          value: "Scatter",
                        },
                        {
                          label: "Line",
                          value: "Line",
                        },
                      ]}
                    />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RuleForm;
