//https://www.sitepoint.com/community/t/searching-for-multiple-words-within-a-string-or-array/6706/5

export const getInitials = (name: string = "") => {
  return name
    .replace(/[^a-zA-Z]+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
};

export const pluralize = (count, noun, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;

export const _capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalize = (sentenace: string, allWords: boolean = false) => {
  if (typeof sentenace !== "string") return "";
  return allWords
    ? sentenace.split(" ").map(_capitalize).join(" ")
    : _capitalize(sentenace);
};

export const multiSearchOr = (text, searchWords) => {
  var searchExp = new RegExp(searchWords.join("|"), "gi");
  return searchExp.test(text);
};

export const multiSearchAnd = (text, searchWords) => {
  var searchExp = new RegExp("(" + searchWords.join(")|(") + ")", "gi");
  return text.match(searchExp)?.length === searchWords?.length;
};

export const isEmptyValue = (value?: any) =>
  value === null || value === undefined || value === "";
