export const config = {
  APP_NAME: "BV OCM",
  APP_SUPPORT_EMAIL:
    process.env.REACT_APP_BV_EMAIL || "BVPort@BureauVeritas.com",
  APP_SUPPORT_PHONE_NUMBER: "",
  APP_HOME: "/app/dashboard",
  APP_ROOT: "/app",
  APP_COMMIT: process.env.REACT_APP_COMMIT || "N/A",
  APP_PUBLISH_DATE: process.env.REACT_APP_PUBLISH_DATE || "N/A",
  APP_ENVIRONMENT: process.env.NODE_ENV || "",
  APP_ENV: process.env.REACT_APP_ENV || "N/A",
  BASE_URL:
    process.env.REACT_APP_BASE_API_URL || "https://sda.bvna.com/WebAPI/Api/",
};
