import React, { createContext, useContext, useReducer } from "react";
import type { ReactNode } from "react";
import fetch from "src/services/fetch";
import IRule from "src/types/IRule";
import * as factory from "src/factory/rule";

type CreateRule = {
  type: "CREATE_RULE";
  payload: {
    rule: IRule;
  };
};

type UpdateRule = {
  type: "UPDATE_RULE";
  payload: {
    rule: IRule;
  };
};

type SetRule = {
  type: "SET_RULE";
  payload: {
    rule: IRule;
  };
};

type GetRules = {
  type: "GET_RULES";
  payload: {
    rules: IRule[];
    totalCount: number;
  };
};

type IsLoading = {
  type: "IS_LOADING";
  payload: {
    parameter: string;
    status: boolean;
  };
};

type SetSelection = {
  type: "SET_SELECTIONS";
  payload: {
    selections: any[];
    cachedRules: IRule[];
  };
};

type GetRule = {
  type: "GET_RULE";
  payload: {
    rule: IRule;
  };
};

type SaveRule = {
  type: "SAVE_RULE";
  payload: {
    rule: IRule;
  };
};

type DeleteRule = {
  type: "DELETE_RULE";
  payload: {
    rule: IRule;
  };
};

type SetCurrentRule = {
  type: "SET_CURRENT_RULE";
  payload: {
    rule: IRule;
  };
};

type ResetState = {
  type: "RESET_STATE";
  payload: {
    initialState: RulesState;
  };
};

type Action =
  | CreateRule
  | UpdateRule
  | SetRule
  | GetRules
  | IsLoading
  | SetSelection
  | GetRule
  | SaveRule
  | DeleteRule
  | SetCurrentRule
  | ResetState;
interface RulesProps {
  children: ReactNode;
}
interface RulesState {
  isDirty: boolean;
  isLoading: any;
  rules: IRule[];
  currentRule?: IRule | null;
  selections?: any[];
  totalCount?: number;
  cachedRules?: IRule[];
}
interface ContextValue {
  state?: RulesState;
  dispatch?: (value: Action) => void;
  setRule?: (rule: IRule) => void;
  updateRule?: () => any;
  createRule?: () => void;
  saveRule?: (paramState: any) => any;
  deleteRule?: (rule: IRule) => void;
  setCurrentRule?: (rule: IRule) => void;
  getRules?: (config: any) => void;
  setSelections?: (selections: any[], limit?: number) => void;
  getRuleById?: (ruleid: number) => void;
  getCachedRuleByUniqueID?: (ruleUniqueID: string) => IRule;
  resetState?: () => void;
}

const RulesContext = createContext<ContextValue>({});
const useRules = (): ContextValue => useContext(RulesContext);

const reducer = (state: RulesState, action: Action): RulesState => {
  let newState = state;

  switch (action.type) {
    case "SAVE_RULE": {
      const { rule } = action.payload;
      newState = { ...state, currentRule: rule, isDirty: true };
      break;
    }

    case "CREATE_RULE": {
      const { rule } = action.payload;
      newState = { ...state, currentRule: rule, isDirty: true };
      break;
    }

    case "UPDATE_RULE": {
      const { rule } = action.payload;
      newState = {
        ...state,
        currentRule: rule,
        isDirty: true,
        cachedRules: state.cachedRules.map((r) =>
          r.ruleUniqueID === rule.ruleUniqueID ? rule : r
        ),
      };
      break;
    }

    case "SET_RULE": {
      const { rule } = action.payload;
      newState = { ...state, currentRule: rule, isDirty: true };
      break;
    }

    case "GET_RULES": {
      const { rules, totalCount } = action.payload;
      newState = { ...state, rules, totalCount };
      break;
    }

    case "IS_LOADING": {
      const { parameter, status } = action.payload;
      newState = {
        ...state,
        isLoading: { ...state.isLoading, [parameter]: status },
      };
      break;
    }

    case "SET_SELECTIONS": {
      const { selections, cachedRules } = action.payload;
      newState = {
        ...state,
        selections,
        cachedRules,
      };
      break;
    }

    case "SET_CURRENT_RULE": {
      const { rule } = action.payload;
      newState = {
        ...state,
        currentRule: rule,
      };
      break;
    }

    case "RESET_STATE": {
      const { initialState } = action.payload;
      newState = {
        ...initialState,
      };

      break;
    }

    case "DELETE_RULE": {
      const { rule } = action.payload;
      newState = {
        ...state,
        rules: state.rules.filter((x) => x.ruleUniqueID !== rule.ruleUniqueID),
        selections: state.selections.filter((x) => x !== rule.ruleUniqueID),
      };
      break;
    }
  }

  return newState;
};

const RulesProvider: React.FC<RulesProps> = ({ children }: RulesProps) => {
  const initialRuleState: RulesState = {
    isDirty: false,
    rules: [],
    currentRule: factory.createRule(),
    isLoading: {
      rules: false,
    },
    selections: [],
    cachedRules: [],
    totalCount: 0,
  };

  const [state, dispatch] = useReducer(reducer, initialRuleState);

  const resetState = () => {
    dispatch({
      type: "RESET_STATE",
      payload: { initialState: initialRuleState },
    });
  };

  const getRules = (config) => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "rules", status: true },
    });

    return fetch
      .get(`/Lookup/GetRules`, config)
      .then((response) => {
        let { lookupList: rules, count } = response.data;
        rules = rules.map((rule) => factory.createRule(rule).deserialize(rule));

        dispatch({
          type: "GET_RULES",
          payload: {
            rules,
            totalCount: count,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "rules", status: false },
        });
      })
      .catch((err) => {
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "rules", status: false },
        });
      });
  };

  const createRule = () => {
    dispatch({
      type: "CREATE_RULE",
      payload: { rule: factory.createRule() },
    });
  };

  const deleteRule = (rule: IRule) => {
    return fetch
      .get(`/Lookup/DeleteRule`, {
        params: {
          ruleID: rule.ruleID,
          testNo: rule.testNo,
          rateOfChangeUnit: rule.rateControl?.rateOfChangeUnit,
        },
      })
      .then((response) => {
        dispatch({
          type: "DELETE_RULE",
          payload: {
            rule,
          },
        });
      });
  };

  const updateRule = () => {
    const rule = state.currentRule;

    return fetch
      .post(`/Lookup/UpdateRule`, factory.createRule(rule).serialize(), {
        params: {
          ruleId: rule.ruleID,
        },
      })
      .then((response) => {
        dispatch({
          type: "UPDATE_RULE",
          payload: {
            rule,
          },
        });
      });
  };

  const setRule = (rule: IRule) => {
    dispatch({
      type: "SET_RULE",
      payload: {
        rule,
      },
    });
  };

  const setSelections = (selections?: any[], limit?: number) => {
    // limit to only 5 sames

    if (selections.length > 5) return;

    // find rules in rules data first

    const combinedRules = [...state.rules, ...state.cachedRules];

    const cachedRules = selections.reduce((acc, selection) => {
      const rule = combinedRules.find((r) => r.ruleUniqueID === selection);
      // did you find it?
      if (rule) {
        return [...acc, rule];
      }

      return acc;
    }, []);

    dispatch({
      type: "SET_SELECTIONS",
      payload: {
        selections,
        cachedRules,
      },
    });
  };

  const setCurrentRule = (rule: IRule) => {
    dispatch({
      type: "SET_CURRENT_RULE",
      payload: {
        rule: factory.createRule(rule),
      },
    });
  };

  const saveRule = (paramState) => {
    return fetch.post(
      `/Lookup/CreateRule`,
      state.currentRule.serialize(paramState)
    );
  };

  const getRuleById = (ruleId: number) => {
    //dispatch({ type: "GET_RULE", payload: { rule: factory.createRule() } });
  };

  const getCachedRuleByUniqueID = (ruleUniqueID: string) => {
    return factory.createRule(
      state.cachedRules.find((r) => r.ruleUniqueID === ruleUniqueID)
    );
  };

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      getRules,
      updateRule,
      setRule,
      createRule,
      deleteRule,
      setCurrentRule,
      setSelections,
      getRuleById,
      getCachedRuleByUniqueID,
      resetState,
    }),
    [state, updateRule]
  );

  return (
    <RulesContext.Provider value={{ ...value, saveRule }}>
      {children}
    </RulesContext.Provider>
  );
};

export { RulesProvider, useRules };
