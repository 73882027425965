import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Check } from "@material-ui/icons";

const BooleanFormatter = ({ value }) => {
  return value ? <Check /> : null;
};

const BooleanTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />
);

export { BooleanTypeProvider, BooleanFormatter };
