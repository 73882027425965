import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  severityColour: {
    color: ({ value }: any) => {
      if (value === 'A') {
        return theme.palette.colors.severity.a;
      }
      if (value === 'B') {
        return theme.palette.colors.severity.b;
      }
      if (value === 'C') {
        return theme.palette.colors.severity.c;
      }
      if (value === 'D') {
        return theme.palette.colors.severity.d;
      }
    },
  },
}));

const SeverityFormatter = ({ value }) => {
  const classes = useStyles({ value });
  return <span className={classes.severityColour}>{value}</span>;
};

const SeverityTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={SeverityFormatter} {...props} />
);

export { SeverityTypeProvider, SeverityFormatter };
