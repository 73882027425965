import React from "react";

import {
  Chart,
  LineSeries,
  ScatterSeries,
  ArgumentAxis,
  ValueAxis,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";

import {
  ValueScale,
  Animation,
  EventTracker,
  HoverState,
  ArgumentScale,
  ZoomAndPan,
} from "@devexpress/dx-react-chart";

import { connectProps } from "@devexpress/dx-react-core";
import { scaleTime } from "d3-scale";
import { Button, Box, Paper } from "@material-ui/core";
import { isEqual } from "src/utils/object";

const sampleValueColor = "#2F80ED";

const patchProps = ({ hoverIndex, ...props }) => ({
  state: props.index === hoverIndex ? "hovered" : null,
  ...props,
});

const LinePoint = (props) => <LineSeries.Point {...patchProps(props)} />;

const pointOptions = { size: 7 };

const SplinePoint = (props) => (
  <ScatterSeries.Point point={pointOptions} {...patchProps(props)} />
);

const ScatterPoints = (props) => <ScatterSeries.Path {...props} />;

const series = [
  { name: "LowerCLimit", key: "lowerCLimit", color: "#FF0000" },
  { name: "LowerBLimit", key: "lowerBLimit", color: "#FFA800" },
  { name: "LowerALimit", key: "lowerALimit", color: "#9E00FF" },
  { name: "UpperCLimit", key: "upperCLimit", color: "#FF0000" },
  { name: "UpperBLimit", key: "upperBLimit", color: "#FFA800" },
  { name: "UpperALimit", key: "upperALimit", color: "#9E00FF" },

  {
    name: "Sample Value",
    key: "value",
    color: sampleValueColor,
    type: ScatterSeries,
  },
];
const toolTipSeries = [
  { name: "Sample #", key: "labNo" },
  { name: "Value", key: "originalValue" },
  { name: "Date", key: "formattedDate" },
];

const TooltipContent = ({ data, text, style, ...props }) => {
  const alignStyle = {
    ...style,
    paddingLeft: "10px",
  };
  const alignStyleForValue = {
    ...style,
    display: "flex",
  };
  const items = toolTipSeries.map(({ name, key, color }) => {
    const val = data[key];
    return (
      <tbody key={key}>
        <tr>
          <td>
            <Tooltip.Content style={alignStyle} text={name} {...props} />
          </td>
          <td align="right">
            <Tooltip.Content
              style={alignStyleForValue}
              // text={val ? formatTooltip(val) : "N/A"}
              text={val}
              {...props}
            />
          </td>
        </tr>
      </tbody>
    );
  });
  return <table>{items}</table>;
};

const getHoverIndex = ({ target }) => (target ? target.point : -1);

export default class ScatterChartComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      target: null,
    };

    this.changeHover = (target) =>
      this.setState({
        target: target ? { series: "Sample Value", point: target.point } : null,
      });

    this.changeViewport = (viewport) => this.setState({ viewport });
    this.resetViewport = () => this.setState({ viewport: null });

    this.createComponents();
    this.createSeries();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setState((prev) => {
      if (!isEqual(prev.data, this.props.data)) this.resetViewport();
      return {
        ...prev,
        data: this.props.data,
      };
    });

    if (getHoverIndex(prevState) !== getHoverIndex(this.state)) {
      this.LinePoint.update();
      this.SplinePoint.update();
      this.TooltipContent.update();
    }
  }

  createComponents() {
    const getHoverProps = () => ({
      hoverIndex: getHoverIndex(this.state),
    });
    this.LinePoint = connectProps(LinePoint, getHoverProps);
    this.SplinePoint = connectProps(SplinePoint, getHoverProps);

    this.TooltipContent = connectProps(TooltipContent, () => {
      const { data, target } = this.state;
      return { data: target ? data[target.point] : null };
    });
  }

  createSeries() {
    this.series = series.map(({ name, key, color, type, scale }) => {
      const props = {
        key: name,
        name,
        valueField: key,
        argumentField: "date",
        color,
        scaleName: scale,
        pointComponent: this.LinePoint,
      };
      if (type === ScatterSeries) {
        props.seriesComponent = ScatterPoints;
        props.pointComponent = this.SplinePoint;
      }
      return React.createElement(type || LineSeries, props);
    });
  }

  render() {
    const { data, target, viewport } = this.state;

    return (
      <Paper>
        <Box p={2}>
          <Button
            onClick={this.resetViewport}
            variant="contained"
            color="primary"
          >
            Reset View
          </Button>
        </Box>
        <Chart data={data}>
          <ValueScale />
          <ArgumentScale factory={scaleTime} />
          <ArgumentAxis />
          <ValueAxis />

          {this.series}

          <ZoomAndPan
            viewport={viewport}
            onViewportChange={this.changeViewport}
            interactionWithValues="both"
          />

          {/* <Animation /> */}

          <EventTracker />
          <HoverState hover={target} onHoverChange={this.changeHover} />
          <Tooltip targetItem={target} contentComponent={this.TooltipContent} />
        </Chart>
      </Paper>
    );
  }
}
