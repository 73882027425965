import type { FC } from "react";
import Slider from "@material-ui/core/Slider";
import { Box, useTheme } from "@material-ui/core";
import { Theme } from "src/theme";

interface Mark {
  value: number;
  label: string;
}
export interface RangeSliderProps {
  [key: string]: any;
  onChange?: (e: any, val: any) => void;
  // onChangeCommitted?: () => void;
  defaultValue?: number[];
  value?: number[];
  marks?: Mark[];
}

const RangeSlider: FC<RangeSliderProps> = ({
  onChange,
  onChangeCommitted,
  defaultValue,
  value,
  marks = [],
  max = 200,
  min = 0,
  disabled = false,
  useUpperLimit = false,
  useLowerLimit = false,
}) => {
  const theme = useTheme<Theme>();
  const { limit } = theme.palette.colors;

  let colors = [];

  if (useUpperLimit) colors = [limit.ua, limit.ub, limit.uc];
  if (useLowerLimit) colors = [limit.lc, limit.lb, limit.la];
  if (useUpperLimit && useLowerLimit)
    colors = [limit.lc, limit.lb, limit.la, limit.ua, limit.ub, limit.uc];

  return (
    <Box mt={6}>
      <Slider
        disabled={disabled}
        key={Math.random()}
        valueLabelDisplay="on"
        aria-labelledby="track-false-range-slider"
        defaultValue={defaultValue}
        value={value}
        marks={marks}
        max={max}
        min={min}
        onChangeCommitted={onChangeCommitted}
        onChange={onChange}
        ThumbComponent={({ style, ...props }) => {
          const index = props["data-index"];
          return <span {...props} style={{ ...style, color: colors[index] }} />;
        }}
      />
    </Box>
  );
};

export default RangeSlider;
