import React, { createContext, ReactNode, useContext, useReducer } from "react";

import type { FC } from "react";

import fetch from "src/services/fetch";
import { IAuditLog } from "src/types/IAuditLog";
import IRule from "src/types/IRule";

interface AuditsContextValue {
  state?: AuditsState | null;
  dispatch?: (value: Action) => void;
  getAudits?: (config: any) => void;
  getAuditByRule?: (rule: IRule, config: any) => void;
}

const AuditsContext = createContext<AuditsContextValue>({});
const useAudits = (): AuditsContextValue => useContext(AuditsContext);

type GetAuditsAction = {
  type: "GET_AUDITS";
  payload: {
    audits: IAuditLog[];
    totalCount: number;
  };
};

type SetCurrentAuditData = {
  type: "SET_CURRENT_AUDIT_DATA";
  payload: {
    currentAuditData: IAuditLog[];
  };
};

type IsLoading = {
  type: "IS_LOADING";
  payload: {
    parameter: string;
    status: boolean;
  };
};

type Action = GetAuditsAction | SetCurrentAuditData | IsLoading;

interface AuditsState {
  audits?: IAuditLog[];
  isLoading?: any;
  totalCount?: number;
}

const reducer = (state: AuditsState, action: Action): AuditsState => {
  let newState = state;

  switch (action.type) {
    case "GET_AUDITS": {
      const { audits, totalCount } = action.payload;
      newState = { ...state, audits, totalCount };
      break;
    }

    case "IS_LOADING": {
      const { parameter, status } = action.payload;
      newState = {
        ...state,
        isLoading: { ...state.isLoading, [parameter]: status },
      };
      break;
    }

    default: {
      return state;
    }
  }
  return newState;
};

interface AuditsProps {
  children: ReactNode;
}

const AuditsProvider: FC<AuditsProps> = ({ children }: AuditsProps) => {
  const initialState: AuditsState = {
    audits: [],
    isLoading: {
      audits: false,
    },
    totalCount: 0,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getAudits = (config) => {
    dispatch({ type: "GET_AUDITS", payload: { audits: [], totalCount: 0 } });

    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "audits", status: true },
    });

    return fetch
      .get(`/Lookup/GetRuleAuditBatch`, config)
      .then((response) => {
        let { auditList, count } = response.data;

        dispatch({
          type: "GET_AUDITS",
          payload: { audits: auditList, totalCount: count },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "audits", status: false },
        });

        return auditList;
      })
      .catch((err) => {});
  };

  const getAuditByRule = (rule: IRule, config?) => {
    dispatch({ type: "GET_AUDITS", payload: { audits: [], totalCount: 0 } });

    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "audits", status: true },
    });

    return fetch
      .get(`/Lookup/GetRuleAudit`, {
        params: {
          ...config?.params,
          ruleId: rule.ruleID,
          RateOfChangeUnit: rule?.rateControl.rateOfChangeUnit,
          testNo: rule.testNo,
        },
      })
      .then((response) => {
        let { auditList, count } = response.data;

        dispatch({
          type: "GET_AUDITS",
          payload: { audits: auditList, totalCount: count },
        });

        // let auditList = response.data;

        // dispatch({
        //   type: "GET_AUDITS",
        //   payload: { audits: auditList, totalCount: 0 },
        // });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "audits", status: false },
        });

        return auditList;
      })
      .catch((err) => {});
  };

  return (
    <AuditsContext.Provider
      value={{
        state,
        dispatch,
        getAudits,
        getAuditByRule,
      }}
    >
      {children}
    </AuditsContext.Provider>
  );
};

export { AuditsProvider, useAudits };
