import React, { useRef, useState } from "react";
import type { FC } from "react";
import Lottie from "src/components/Lottie";

import loaderShort from "./assets/bv-loader-short.json";
import loaderLong from "./assets/bv-loader-long.json";
import loaderRing from "./assets/bv-loader-ring.json";
import { useEffect } from "react";

export interface LoaderProps {
  [key: string]: any;
  style?: any;
}

export enum LoaderType {
  LONG,
  SHORT,
  RING,
}

const loaders = {
  [LoaderType.LONG]: loaderLong,
  [LoaderType.SHORT]: loaderShort,
  [LoaderType.RING]: loaderRing,
};

function parentHeight(elem) {
  return elem.parentElement.clientHeight;
}

const Loader: FC<LoaderProps> = ({ style, loaderType = LoaderType.SHORT }) => {
  const ref = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(parentHeight(ref.current));
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "content",
        textAlign: "center",
        height: height === 0 ? "100%" : height,
        width: "100%",
      }}
    >
      <Lottie
        data={loaders[loaderType]}
        style={{ width: "96px", margin: "auto", ...style }}
      />
    </div>
  );
};

export default Loader;
