import React, { useState } from "react";
import fetch from "src/services/fetch";
import * as factory from "src/factory/comment";
import IComment from "src/types/IComment";

const initialState = { comments: [], isLoading: false };

const useComments = () => {
  const [state, setState] = useState(initialState);

  const getComments = (progCode: string) => {
    setState((prev) => ({ ...prev, isLoading: true }));

    return fetch
      .get(`/Lookup/GetRuleComments`, {
        params: {
          progCode,
        },
      })
      .then((response) => {
        const comments = response.data.map((c) => factory.createComment(c));

        setState((prev) => ({ ...prev, comments, isLoading: false }));

        return comments;
      })
      .catch((err) => {});
  };

  const updateComment = (comment: IComment, programCode: string) => {
    // setState((prev) => ({ ...prev, isLoading: true }));

    return fetch
      .get(`/Lookup/UpdateRuleComment`, {
        params: {
          progCode: programCode,
          text: comment.commentText,
          commentIndex: comment.commentIndex,
        },
      })
      .catch((err) => {});
  };

  const clearComments = () => setState(initialState);

  const value = React.useMemo(
    () => ({
      state,
      getComments,
      updateComment,
      clearComments,
    }),
    [state]
  );

  return value;
};

export default useComments;
