import React from "react";
import type { FC } from "react";

import { makeStyles, Typography, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isObjectEmpty } from "src/utils/object";
import useSettings from "src/hooks/useSettings";
import FormikHelper from "./FormikHelper";
import { Prompt } from "react-router-dom";
interface Props {
  [key: string]: any;
}

const useStyles = makeStyles(() => ({
  root: {},
  link: {
    cursor: "pointer",
  },
}));

const ErrorPanel: FC<Props> = ({
  errors = {},
  values = {},
  touched = {},
  isDirty = false,
}) => {
  const classes = useStyles();
  const { settings } = useSettings();

  // https://stackoverflow.com/questions/42503599/how-to-make-javascript-scrollintoview-smooth
  const handlescrollIntoView = (key) => {
    var elmnt = document.getElementsByName(key)[0];

    if (elmnt) elmnt.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  return (
    <>
      <Prompt
        message="You will lose your changes if you leave this page"
        when={isDirty}
      />
      {settings.debug && (
        <FormikHelper errors={errors} values={values} touched={touched} />
      )}
      {!isObjectEmpty(errors) && (
        <Box mb={2}>
          <Alert severity="error" style={{ paddingTop: 0 }}>
            <Typography variant="h6">Please Note...</Typography>

            <ul style={{ fontSize: 10 }}>
              {Object.entries(errors).map(([key, value]) => (
                <li
                  key={key}
                  className={classes.link}
                  onClick={() => handlescrollIntoView(key)}
                >
                  {JSON.stringify(value)?.replaceAll('"', "")}
                </li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default ErrorPanel;
