import React from "react";

import CommandsCell from "./CommandsCell";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Button } from "@material-ui/core";

const Cell = (props) => {
  const { onCommentCodeChange, onAudit, onDelete, onOpen, ...rest } = props;

  if (props.column.name === "command") {
    return (
      <Table.Cell {...rest}>
        <CommandsCell {...props} />
      </Table.Cell>
    );
  }

  if (props.column.name === "action") {
    return (
      <Table.Cell {...rest}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => onCommentCodeChange(props.row)}
        >
          Apply
        </Button>
      </Table.Cell>
    );
  }

  return <Table.Cell {...rest} />;
};

export default Cell;
