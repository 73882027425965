import {
  Template,
  TemplatePlaceholder,
  Plugin,
} from "@devexpress/dx-react-core";
import { IconButton, Tooltip } from "@material-ui/core";
import { Save } from "@material-ui/icons";
const pluginDependencies = [{ name: "Toolbar" }];

const CustomExport = ({ onClick }) => {
  return (
    <Plugin name="ToolbarCustomExport" dependencies={pluginDependencies}>
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Tooltip title="Export All" key="export-all">
          <IconButton onClick={onClick}>
            <Save />
          </IconButton>
        </Tooltip>
      </Template>
    </Plugin>
  );
};

export default CustomExport;
