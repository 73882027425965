import React from "react";
import type { FC } from "react";
import type { Theme } from "src/theme";
import { makeStyles } from "@material-ui/core";
import background from "src/assets/images/background.jpg";
import { Outlet } from "react-router";

interface MainLayoutProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    backgroundColor: theme.palette.background.dark,
    background: `url(${background})`,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children || <Outlet />}</div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
