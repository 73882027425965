import { useEffect, useRef, FC } from "react";
import lottie from "lottie-web";

export interface LottieProps {
  [key: string]: any;
  onComplete?: () => void;
}

const Lottie: FC<LottieProps> = ({
  data,
  onComplete,
  loop = true,
  autoplay = true,
  style = { width: 96, height: 96 },
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const anim = lottie.loadAnimation({
      container: ref.current,
      animationData: data,
      renderer: "svg",
      loop,
      autoplay,
    });

    if (onComplete) anim.addEventListener("complete", onComplete);

    return () => {
      anim.removeEventListener("complete", onComplete);
      anim.stop();
      anim.destroy();
    };
  }, [ref, data, loop, autoplay, onComplete]);

  return <div ref={ref} style={{ ...style, transform: "translateZ(0)" }} />;
};

export default Lottie;
