import React, { useEffect, useState } from "react";
import type { FC } from "react";
import type IRule from "src/types/IRule";

import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  makeStyles,
  Box,
  Button,
} from "@material-ui/core";

import RangeSlider from "../RangeSlider";
import SearchForm from "../SearchForm";
import ResultsForm from "../ResultsForm";
import { useRules } from "src/contexts/RulesContext";
import useDebounce from "src/hooks/useDebounce";
import { isEqual } from "src/utils/object";
import { limitDecimals } from "src/utils/number";
import { Theme } from "src/theme";
import { Warning } from "@material-ui/icons";
import { useParameters } from "src/contexts/ParametersContext";
import AutocompleteParameter from "../RuleParameterForm/AutocompleteParameter";
import { Mode } from "src/types/IForm";
import ErrorPanel from "../Forms/ErrorPanel";
import { Scalable } from "src/constants/scalable";
export interface LimitUnitProps {
  label?: string;
  value?: string;
}
export interface CommentCodeProps {
  label?: string;
  value?: string;
}
export interface RateOfChangeUnitProps {
  label?: string;
  value?: string;
}

export interface LimitFormProps {
  [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tab: { minWidth: 85, width: 85 },
  lc: {
    borderColor: theme.palette.colors.limit.lc,
    "&$disabled": {
      borderColor: theme.palette.colors.limit.lc,
    },
  },
  lb: {
    borderColor: theme.palette.colors.limit.lb,
    "&$disabled": {
      borderColor: theme.palette.colors.limit.lb,
    },
  },
  la: {
    borderColor: theme.palette.colors.limit.la,
    "&$disabled": {
      borderColor: theme.palette.colors.limit.la,
    },
  },
  ua: {
    borderColor: theme.palette.colors.limit.ua,
    "&$disabled": {
      borderColor: theme.palette.colors.limit.ua,
    },
  },
  ub: {
    borderColor: theme.palette.colors.limit.ub,
    "&$disabled": {
      borderColor: theme.palette.colors.limit.ub,
    },
  },
  uc: {
    borderColor: theme.palette.colors.limit.uc,
    "&$disabled": {
      borderColor: theme.palette.colors.limit.uc,
    },
  },
}));

function a11yProps(index) {
  return {
    id: `limit-tab-${index}`,
    "aria-controls": `limit-tabpanel-${index}`,
  };
}

const LimitForm: FC<LimitFormProps> = ({
  mode = Mode.CREATE,
  isDisabled = true,
}) => {
  const classes = useStyles();
  const { state: ruleState, setRule } = useRules();
  const [ruleStateTemp, setRuleStateTemp] = useState<IRule>(
    ruleState.currentRule
  );

  const {
    state: paramState,
    selectOption,
    setParameterSearchTerm,
  } = useParameters();

  const [ruleErrors, setRuleErrors] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const updatedRule = useDebounce(ruleStateTemp, 500);

  useEffect(() => {
    if (isInitialized && !isEqual(updatedRule, ruleState.currentRule)) {
      setRule(updatedRule);
    }

    setIsInitialized(true);
  }, [updatedRule]);

  useEffect(() => {
    setRuleStateTemp(ruleState.currentRule);
    setRuleErrors(ruleState.currentRule.isValid());
  }, [ruleState.currentRule]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <>
      <Tabs
        value={selectedTabIndex}
        onChange={(event, value) => setSelectedTabIndex(value)}
        style={{ width: "100%" }}
        variant="scrollable"
      >
        <Tab className={classes.tab} label="LIMITS" {...a11yProps(0)} />

        <Tab
          className={classes.tab}
          icon={
            ruleErrors?.lc && mode !== Mode.READONLY ? (
              <Button
                color="secondary"
                endIcon={<Warning style={{ color: "orange" }} />}
                disableRipple
                style={{ backgroundColor: "transparent" }}
              >
                LC
              </Button>
            ) : null
          }
          label={!ruleErrors?.lc ? "LC" : null}
          {...a11yProps(1)}
          disabled={!ruleStateTemp.useLowerLimit()}
        />
        <Tab
          className={classes.tab}
          icon={
            ruleErrors?.lb && mode !== Mode.READONLY ? (
              <Button
                color="secondary"
                endIcon={<Warning style={{ color: "orange" }} />}
                disableRipple
                style={{ backgroundColor: "transparent" }}
              >
                LB
              </Button>
            ) : null
          }
          label={!ruleErrors?.lb ? "LB" : null}
          {...a11yProps(2)}
          disabled={!ruleStateTemp.useLowerLimit()}
        />
        <Tab
          className={classes.tab}
          icon={
            ruleErrors?.la && mode !== Mode.READONLY ? (
              <Button
                color="secondary"
                endIcon={<Warning style={{ color: "orange" }} />}
                disableRipple
                style={{ backgroundColor: "transparent" }}
              >
                LA
              </Button>
            ) : null
          }
          label={!ruleErrors?.la ? "LA" : null}
          {...a11yProps(3)}
          disabled={!ruleStateTemp.useLowerLimit()}
        />

        <Tab
          className={classes.tab}
          icon={
            ruleErrors?.ua && mode !== Mode.READONLY ? (
              <Button
                color="secondary"
                endIcon={<Warning style={{ color: "orange" }} />}
                disableRipple
                style={{ backgroundColor: "transparent" }}
              >
                UA
              </Button>
            ) : null
          }
          label={!ruleErrors?.ua ? "UA" : null}
          {...a11yProps(4)}
          disabled={!ruleStateTemp.useUpperLimit()}
        />
        <Tab
          className={classes.tab}
          icon={
            ruleErrors?.ub && mode !== Mode.READONLY ? (
              <Button
                color="secondary"
                endIcon={<Warning style={{ color: "orange" }} />}
                disableRipple
                style={{ backgroundColor: "transparent" }}
              >
                UB
              </Button>
            ) : null
          }
          label={!ruleErrors?.ub ? "UB" : null}
          {...a11yProps(5)}
          disabled={!ruleStateTemp.useUpperLimit()}
        />
        <Tab
          className={classes.tab}
          icon={
            ruleErrors?.uc && mode !== Mode.READONLY ? (
              <Button
                color="secondary"
                endIcon={<Warning style={{ color: "orange" }} />}
                disableRipple
                style={{ backgroundColor: "transparent" }}
              >
                UC
              </Button>
            ) : null
          }
          label={!ruleErrors?.uc ? "UC" : null}
          {...a11yProps(6)}
          disabled={!ruleStateTemp.useUpperLimit()}
        />
      </Tabs>
      {selectedTabIndex === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={ruleStateTemp.useLowerLimit()} />}
              label="Use lower limits"
            />

            <FormControlLabel
              control={<Checkbox checked={ruleStateTemp.useUpperLimit()} />}
              label="Use upper limits"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={isDisabled}
              InputProps={{
                classes: {
                  notchedOutline: classes.lc,
                },
              }}
              label="LC"
              name="lc"
              value={ruleStateTemp.lowerLimit?.cLimit ?? ""}
              variant="outlined"
              onKeyPress={(e) => {
                if (!e.key.match(/^[-.?\d]+$/)) e.preventDefault();
              }}
              onChange={(event) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  lowerLimit: {
                    ...ruleStateTemp.lowerLimit,
                    cLimit: limitDecimals(event.target.value),
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={isDisabled}
              InputProps={{
                classes: {
                  notchedOutline: classes.lb,
                },
              }}
              label="LB"
              name="lb"
              value={ruleStateTemp.lowerLimit?.bLimit ?? ""}
              variant="outlined"
              onKeyPress={(e) => {
                if (!e.key.match(/^[-.?\d]+$/)) e.preventDefault();
              }}
              onChange={(event) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  lowerLimit: {
                    ...ruleStateTemp.lowerLimit,
                    bLimit: limitDecimals(event.target.value),
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={isDisabled}
              label="LA"
              name="la"
              value={ruleStateTemp.lowerLimit?.aLimit ?? ""}
              variant="outlined"
              onKeyPress={(e) => {
                if (!e.key.match(/^[-.?\d]+$/)) e.preventDefault();
              }}
              onChange={(event) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  lowerLimit: {
                    ...ruleStateTemp.lowerLimit,
                    aLimit: limitDecimals(event.target.value),
                  },
                });
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.la,
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={isDisabled}
              label="UA"
              name="ua"
              value={ruleStateTemp.upperLimit?.aLimit ?? ""}
              variant="outlined"
              onKeyPress={(e) => {
                if (!e.key.match(/^[-.?\d]+$/)) e.preventDefault();
              }}
              onChange={(event) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  upperLimit: {
                    ...ruleStateTemp.upperLimit,
                    aLimit: limitDecimals(event.target.value),
                  },
                });
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.ua,
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={isDisabled}
              label="UB"
              name="ub"
              value={ruleStateTemp.upperLimit?.bLimit ?? ""}
              variant="outlined"
              onKeyPress={(e) => {
                if (!e.key.match(/^[-.?\d]+$/)) e.preventDefault();
              }}
              onChange={(event) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  upperLimit: {
                    ...ruleStateTemp.upperLimit,
                    bLimit: limitDecimals(event.target.value),
                  },
                });
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.ub,
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={isDisabled}
              label="UC"
              name="uc"
              value={ruleStateTemp.upperLimit?.cLimit ?? ""}
              variant="outlined"
              onKeyPress={(e) => {
                if (!e.key.match(/^[-.?\d]+$/)) e.preventDefault();
              }}
              onChange={(event) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  upperLimit: {
                    ...ruleStateTemp.upperLimit,
                    cLimit: limitDecimals(event.target.value),
                  },
                });
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.uc,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ overflow: "hidden" }}>
            <Box mx={2}>
              <RangeSlider
                useUpperLimit={ruleStateTemp.useUpperLimit()}
                useLowerLimit={ruleStateTemp.useLowerLimit()}
                {...ruleStateTemp.getMarginOffsets()}
                marks={ruleStateTemp.generateMarks()}
                value={ruleStateTemp.getLimits()}
                // onChangeCommitted={(e, val) => {
                //   setRuleStateTemp({
                //     ...ruleStateTemp,
                //     lowerLimit: {
                //       ...ruleStateTemp.lowerLimit,
                //       cLimit: val[0],
                //       bLimit: val[1],
                //       aLimit: val[2],
                //     },
                //     upperLimit: {
                //       ...ruleStateTemp.upperLimit,
                //       aLimit: val[3],
                //       bLimit: val[4],
                //       cLimit: val[5],
                //     },
                //   });
                // }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              disabled={isDisabled}
              name="graphLowerUnit"
              label="Graph Lower Limit"
              value={ruleStateTemp.getMarginOffsets().min}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              disabled={isDisabled}
              name="graphUpperUnit"
              label="Graph Upper Limit"
              value={ruleStateTemp.getMarginOffsets().max}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              name="limitUnit"
              value={
                ruleStateTemp.lowerLimit?.limitUnit ??
                ruleStateTemp.upperLimit?.limitUnit ??
                ""
              } // TODO: added to the deserializion of the rule and add to the root rule
              label="Limit Unit"
              variant="outlined"
              disabled={isDisabled}
            />
          </Grid>
          {ruleStateTemp.scalable === Scalable.TRUE && (
            <>
              <Grid item xs={4}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  name="aRateOfChange"
                  label="Wear Rate (A)"
                  value={ruleStateTemp.rateControl.aRateOfChange}
                  variant="outlined"
                  onChange={(event) => {
                    setRuleStateTemp({
                      ...ruleStateTemp,
                      rateControl: {
                        ...ruleStateTemp.rateControl,
                        aRateOfChange: event.target.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  name="bRateOfChange"
                  label="Wear Rate (B)"
                  value={ruleStateTemp.rateControl.bRateOfChange}
                  variant="outlined"
                  onChange={(event) => {
                    setRuleStateTemp({
                      ...ruleStateTemp,
                      rateControl: {
                        ...ruleStateTemp.rateControl,
                        bRateOfChange: event.target.value,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled={isDisabled}
                  name="cRateOfChange"
                  value={ruleStateTemp.rateControl.cRateOfChange}
                  label="Wear Rate (C)"
                  variant="outlined"
                  onChange={(event) => {
                    setRuleStateTemp({
                      ...ruleStateTemp,
                      rateControl: {
                        ...ruleStateTemp.rateControl,
                        cRateOfChange: event.target.value,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={isDisabled}
                  name="rateOfChange"
                  label="Control Value"
                  value={ruleStateTemp.rateControl?.rateOfChangeValue ?? ""}
                  onKeyPress={(e) => {
                    if (!e.key.match(/^[-.?\d]+$/)) e.preventDefault();
                  }}
                  onChange={(event) => {
                    setRuleStateTemp({
                      ...ruleStateTemp,
                      rateControl: {
                        ...ruleStateTemp.rateControl,
                        rateOfChangeValue: limitDecimals(event.target.value),
                      },
                    });
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                {mode === Mode.READONLY ? (
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    name="rateOfChangeUnit"
                    value={ruleStateTemp.rateControl.rateOfChangeUnit}
                    label="Control Value Unit"
                    variant="outlined"
                  />
                ) : (
                  <AutocompleteParameter
                    key="rateOfChangeUnit"
                    state={paramState}
                    disabled={isDisabled}
                    selectOption={selectOption}
                    defaultValue={ruleStateTemp?.rateControl?.rateOfChangeUnit}
                    setParameterSearchTerm={setParameterSearchTerm}
                    parameter="getRateChangeUnits"
                    label="Control Value Unit"
                    name="rateOfChangeUnit"
                  />
                )}
              </Grid>
            </>
          )}
          {mode !== Mode.READONLY && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  multiline
                  minRows={3}
                  name="remarks"
                  value={ruleStateTemp.remarks ?? ""}
                  label="Remarks: (reason for creating or editing a rule)"
                  variant="outlined"
                  onChange={(event) => {
                    setRuleStateTemp({
                      ...ruleStateTemp,
                      remarks: event.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <ErrorPanel
                  errors={ruleErrors ?? {}}
                  isDirty={ruleState.isDirty}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}

      {selectedTabIndex === 1 && (
        <>
          <ResultsForm
            limit={ruleState.currentRule?.lowerLimit?.cLimit}
            unit={ruleState.currentRule?.lowerLimit?.limitUnit}
            commentCode={ruleState.currentRule?.lowerLimit?.cCommentIndex}
            comment={ruleState.currentRule?.lowerLimit?.cComment}
            onCommentChange={(comment) => {
              setRuleStateTemp({
                ...ruleStateTemp,
                lowerLimit: {
                  ...ruleStateTemp.lowerLimit,
                  cComment: comment.commentText,
                  cCommentIndex: comment.commentIndex,
                },
              });
            }}
          />
          {mode !== Mode.READONLY && (
            <SearchForm
              onCommentCodeChange={(comment) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  lowerLimit: {
                    ...ruleStateTemp.lowerLimit,
                    cComment: comment.commentText,
                    cCommentIndex: comment.commentIndex,
                  },
                });
              }}
            />
          )}
        </>
      )}
      {selectedTabIndex === 2 && (
        <>
          <ResultsForm
            limit={ruleState.currentRule?.lowerLimit?.bLimit}
            unit={ruleState.currentRule?.lowerLimit?.limitUnit}
            commentCode={ruleState.currentRule?.lowerLimit?.bCommentIndex}
            comment={ruleState.currentRule?.lowerLimit?.bComment}
            onCommentChange={(comment) => {
              setRuleStateTemp({
                ...ruleStateTemp,
                lowerLimit: {
                  ...ruleStateTemp.lowerLimit,
                  bComment: comment.commentText,
                  bCommentIndex: comment.commentIndex,
                },
              });
            }}
          />
          {mode !== Mode.READONLY && (
            <SearchForm
              onCommentCodeChange={(comment) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  lowerLimit: {
                    ...ruleStateTemp.lowerLimit,
                    bComment: comment.commentText,
                    bCommentIndex: comment.commentIndex,
                  },
                });
              }}
            />
          )}
        </>
      )}
      {selectedTabIndex === 3 && (
        <>
          <ResultsForm
            limit={ruleState.currentRule?.lowerLimit?.aLimit}
            unit={ruleState.currentRule?.lowerLimit?.limitUnit}
            commentCode={ruleState.currentRule?.lowerLimit?.aCommentIndex}
            comment={ruleState.currentRule?.lowerLimit?.aComment}
            onCommentChange={(comment) => {
              setRuleStateTemp({
                ...ruleStateTemp,
                lowerLimit: {
                  ...ruleStateTemp.lowerLimit,
                  aComment: comment.commentText,
                  aCommentIndex: comment.commentIndex,
                },
              });
            }}
          />
          {mode !== Mode.READONLY && (
            <SearchForm
              onCommentCodeChange={(comment) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  lowerLimit: {
                    ...ruleStateTemp.lowerLimit,
                    aComment: comment.commentText,
                    aCommentIndex: comment.commentIndex,
                  },
                });
              }}
            />
          )}
        </>
      )}
      {selectedTabIndex === 4 && (
        <>
          <ResultsForm
            limit={ruleState.currentRule?.upperLimit?.aLimit}
            unit={ruleState.currentRule?.upperLimit?.limitUnit}
            commentCode={ruleState.currentRule?.upperLimit?.aCommentIndex}
            comment={ruleState.currentRule?.upperLimit?.aComment}
            onCommentChange={(comment) => {
              setRuleStateTemp({
                ...ruleStateTemp,
                upperLimit: {
                  ...ruleStateTemp.upperLimit,
                  aComment: comment.commentText,
                  aCommentIndex: comment.commentIndex,
                },
              });
            }}
          />
          {mode !== Mode.READONLY && (
            <SearchForm
              onCommentCodeChange={(comment) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  upperLimit: {
                    ...ruleStateTemp.upperLimit,
                    aComment: comment.commentText,
                    aCommentIndex: comment.commentIndex,
                  },
                });
              }}
            />
          )}
        </>
      )}
      {selectedTabIndex === 5 && (
        <>
          <ResultsForm
            limit={ruleState.currentRule?.upperLimit?.bLimit}
            unit={ruleState.currentRule?.upperLimit?.limitUnit}
            commentCode={ruleState.currentRule?.upperLimit?.bCommentIndex}
            comment={ruleState.currentRule?.upperLimit?.bComment}
            onCommentChange={(comment) => {
              setRuleStateTemp({
                ...ruleStateTemp,
                upperLimit: {
                  ...ruleStateTemp.upperLimit,
                  bComment: comment.commentText,
                  bCommentIndex: comment.commentIndex,
                },
              });
            }}
          />
          {mode !== Mode.READONLY && (
            <SearchForm
              onCommentCodeChange={(comment) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  upperLimit: {
                    ...ruleStateTemp.upperLimit,
                    bComment: comment.commentText,
                    bCommentIndex: comment.commentIndex,
                  },
                });
              }}
            />
          )}
        </>
      )}
      {selectedTabIndex === 6 && (
        <>
          <ResultsForm
            limit={ruleState.currentRule?.upperLimit?.cLimit}
            unit={ruleState.currentRule?.upperLimit?.limitUnit}
            commentCode={ruleState.currentRule?.upperLimit?.cCommentIndex}
            comment={ruleState.currentRule?.upperLimit?.cComment}
            onCommentChange={(comment) => {
              setRuleStateTemp({
                ...ruleStateTemp,
                upperLimit: {
                  ...ruleStateTemp.upperLimit,
                  cComment: comment.commentText,
                  cCommentIndex: comment.commentIndex,
                },
              });
            }}
          />
          {mode !== Mode.READONLY && (
            <SearchForm
              onCommentCodeChange={(comment) => {
                setRuleStateTemp({
                  ...ruleStateTemp,
                  upperLimit: {
                    ...ruleStateTemp.upperLimit,
                    cComment: comment.commentText,
                    cCommentIndex: comment.commentIndex,
                  },
                });
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default LimitForm;
