import React from "react";
import type { FC } from "react";
import columns from "./columns";
import SimpleTable from "../SimpleTable";

export interface CommentsTableProps {
  [key: string]: any;
}

const CommentsTable: FC<CommentsTableProps> = ({ ...rest }) => {
  return (
    <SimpleTable
      columns={columns}
      {...rest}
      getRowId={(comment) => comment.commentIndex}
    />
  );
};

export default CommentsTable;
