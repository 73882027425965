import React, { useEffect, useState } from "react";
import type { FC } from "react";

import {
  makeStyles,
  Grid,
  Chip,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import useComments from "src/hooks/useComments";
import { useParameters } from "src/contexts/ParametersContext";
import CommentsTable from "../Tables/CommentsTable";
import { multiSearchOr } from "src/utils/string";

export interface ChipProps {
  key?: number;
  label?: string;
}

export interface OptionProps {
  id?: number;
  value?: string;
  label?: string;
  title?: string;
}
export interface SearchFormProps {
  [key: string]: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const SearchForm: FC<SearchFormProps> = ({ onCommentCodeChange }) => {
  const classes = useStyles();
  const [chips, setChips] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]); // TODO: move into useComments in the future
  const [searchValue, setSearchValue] = useState("");
  const [isProgramCodeError, setIsProgramCodeError] = useState(false);

  const { state: commentsState, getComments, clearComments } = useComments();
  const { state: paramsState } = useParameters();

  const handleDelete = (chipToDelete) => () => {
    setChips((prev) => prev.filter((chip) => chip.key !== chipToDelete.key));
  };

  const addChip = () => {
    setChips((prev) => [
      ...prev,
      {
        key: prev.length > 0 ? prev[prev.length - 1].key + 1 : 0,
        label: searchValue,
      },
    ]);
    setSearchValue("");
  };

  useEffect(() => {
    if (!paramsState.selections?.programs?.program_Code) {
      setIsProgramCodeError(true);
      return;
    }

    if (commentsState.isLoading) return;

    if (chips.length > 0 && commentsState.comments.length === 0) {
      // load comments if I have chips
      getComments(paramsState.selections.programs.program_Code);
    }

    // find in table the chips
  }, [chips, commentsState, paramsState]);

  useEffect(() => {
    //filter rows to search chips
    if (commentsState.comments.length > 0 && chips.length > 0) {
      const chipLabels = chips.map((c) => c.label);

      setFilteredComments(
        commentsState.comments.filter(
          (c) =>
            multiSearchOr(c.commentIndex, chipLabels) ||
            multiSearchOr(c.commentText, chipLabels)
        )
      );
    } else {
      clearComments();
      setFilteredComments([]);
    }
  }, [commentsState.comments, chips]);

  useEffect(() => {
    if (paramsState.selections?.programs?.program_Code)
      setIsProgramCodeError(false);
  }, [paramsState.selections?.programs]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {chips.map((data) => (
            <Chip
              key={data.key}
              label={data.label}
              onDelete={handleDelete(data)}
              className={classes.chip}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Search For Comment Codes"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            className={classes.root}
            InputProps={{
              onKeyDown: (event) => {
                if (event.keyCode === 13) {
                  addChip();
                }
              },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          {isProgramCodeError && "Please select a program"}

          <CommentsTable
            onCommentCodeChange={onCommentCodeChange}
            rows={filteredComments}
            isLoading={commentsState.isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SearchForm;
