const columns = [
  {
    name: "id",
    title: "Audit ID",
    default: true,
    exclude: true,
    width: 125,
  },
  { name: "changedOn", title: "Changed On", default: true, width: 200 },
  {
    name: "changedBy",
    title: "Changed By",
    default: true,
    width: 250,
  },
  { name: "ruleID", title: "Rule ID", default: true, width: 125 },
  { name: "resultCode", title: "Result Code", default: true, width: 125 },
  {
    name: "rateOfChangeUnit",
    title: "Control Value Unit",
    default: true,
    width: 160,
  },
  {
    name: "fieldChanged",
    title: "Field Changed",
    default: true,
    width: 150,
  },
  { name: "limitType", title: "Limit Type", default: true, width: 125 },
  { name: "previousEntry", title: "Previous Entry", default: true, width: 150 },
  { name: "currentEntry", title: "Current Entry", default: true, width: 150 },
  { name: "remarks", title: "Remarks", default: true, width: 150 },
  { name: "testNo", title: "Test No", default: false, width: 125 },
];

export default columns;
