import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import jwtDecode from "jwt-decode";
import fetch from "src/services/fetch";
import { authenicate, getMyAccountInfo } from "src/services/apis/user";
import type { IUser } from "src/types/IUser";
import { createUser } from "src/factory/user";
import Emitter from "src/services/emitter";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  redirect?: string;
  user: IUser | null;
  shouldRedirect?: boolean;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string, redirect?: string) => void;
  logout: (redirect?: boolean) => void;
  isSessionValid: () => boolean;
  updateUser: (user: IUser) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: "INITIALISE";
  payload: {
    isAuthenticated: boolean;
    user: IUser | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: IUser;
    redirect?: string;
  };
};

type LogoutAction = {
  type: "LOGOUT";
  payload: { redirect?: boolean };
};

type UpdateUserAction = {
  type: "UPDATE_USER";
  payload: {
    user: IUser;
  };
};

type Action = InitialiseAction | LoginAction | LogoutAction | UpdateUserAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  redirect: "",
  shouldRedirect: true,
  user: null,
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken?: string): void => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    fetch.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete fetch.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        redirect: "",
        user,
      };
    }
    case "LOGIN": {
      const { user, redirect } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        redirect,
        user,
      };
    }
    case "LOGOUT": {
      const { redirect } = action.payload;

      return {
        ...state,
        isAuthenticated: false,
        redirect: "",
        shouldRedirect: redirect,
        user: null,
      };
    }
    case "UPDATE_USER": {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => null,
  updateUser: () => null,
  isSessionValid: () => false,
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email: string, password: string, redirect?: string) => {
    const authenicateUser = await authenicate(email, password);
    setSession(authenicateUser.token);

    const user = await getMyAccountInfo();

    dispatch({
      type: "LOGIN",
      payload: {
        user: createUser({
          ...authenicateUser,
          ...user,
        }),
        redirect,
      },
    });
  };

  const logout = async (redirect = true) => {
    Emitter.emit("USER", { action: "LOGOUT" });
    dispatch({ type: "LOGOUT", payload: { redirect } });
    setSession();
  };

  const updateUser = async (user: IUser) => {
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const isSessionValid = () =>
    isValidToken(localStorage.getItem("accessToken"));

  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          //let user = await getMe();

          let user = await getMyAccountInfo();

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  // if (!state.isInitialised) {
  //   return <Loader />;
  // }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateUser,
        isSessionValid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
