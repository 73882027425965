import type { FC } from "react";
import type { IUser } from "src/types/IUser";
import rules from "./rbac-rules";
import useAuth from "src/hooks/useAuth";

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action?.toLowerCase())) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

interface SecurityProps {
  [key: string]: any;
}

const Security: FC<SecurityProps> = ({
  perform,
  data,
  yes = () => null,
  no = () => null,
}) => {
  const { user } = useAuth();

  return check(rules, user?.roleID, perform, data) ? yes() : no();
};

const security = (user: IUser, action: string, data?: any) => {
  return check(rules, user?.roleID, action, data);
};

export default Security;
export { security, rules };
