import React, { useState } from "react";
import type { FC } from "react";
import { makeStyles, Grid, Typography, Box } from "@material-ui/core";

import ScatterChartComponent from "./ScatterChartComponent";
import { Theme } from "src/theme";
import Loader from "src/components/Loader";
export interface GraphStyleProps {
  label?: string;
  value?: string;
}
export interface GraphComponentProps {
  className?: string;
  title?: string;
  subtitle?: string;
  graphStyles?: GraphStyleProps[];
  chartData?: any[];
  modifyDomain?: number[];
  [key: string]: any;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  gridItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  datePicker: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const GraphComponent: FC<GraphComponentProps> = ({
  className,
  children,
  title = "",
  subtitle = "",
  graphStyles = [],
  chartData = [],
  modifyDomain = [0, 140],
  key,
  isLoading = false,
  ...rest
}) => {
  const classes = useStyles();
  const [graphStyle] = useState(graphStyles[0].value);

  if (isLoading) return <Loader />;

  return (
    <Grid container spacing={2} key={key}>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="h3">Data</Typography>
        <Box>
          {/* <Grid container spacing={2}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                fullWidth
                className={classes.datePicker}
                label="Starting Date"
                format="MM/DD/YYYY"
                name="startingDate"
                inputVariant="outlined"
                value={new Date()}
                onChange={(date) => {}}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                fullWidth
                className={classes.datePicker}
                label="End Date"
                format="MM/DD/YYYY"
                name="endDate"
                inputVariant="outlined"
                value={new Date()}
                onChange={(date) => {}}
              />
            </Grid>
          </Grid> */}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {graphStyle === "Scatter" && (
          <ScatterChartComponent data={chartData} modifyDomain={modifyDomain} />
        )}
      </Grid>
    </Grid>
  );
};

export default GraphComponent;
