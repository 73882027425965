import React from "react";
import type { FC } from "react";

import {
  TextField as MuiTextField,
  Typography,
  OutlinedTextFieldProps,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Edit, Save } from "@material-ui/icons";

export interface TextFieldProps extends OutlinedTextFieldProps {
  [key: string]: any;
  readonly?: boolean;
  stacked?: boolean;
  onEdit?: (event?: any) => any;
  onSave?: (event?: any) => any;
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    readonly = false,
    label,
    value,
    stacked = true,
    onEdit,
    onSave,
  } = props;

  return (
    <>
      {readonly ? (
        <>
          <Typography
            gutterBottom
            variant="caption"
            color="textSecondary"
            component={!stacked ? "span" : null}
          >
            {label}:
            {onEdit && (
              <Tooltip title={`Edit ${label}`}>
                <IconButton size="small" onClick={onEdit}>
                  <Edit style={{ fontSize: 16 }} />
                </IconButton>
              </Tooltip>
            )}
            {onSave && (
              <Tooltip title={`Save ${label}`}>
                <IconButton size="small" onClick={onSave}>
                  <Save style={{ fontSize: 16 }} />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          {!stacked && <>&nbsp;</>}
          <Typography
            gutterBottom
            variant="body1"
            color="textPrimary"
            component={!stacked ? "span" : null}
          >
            {value}
          </Typography>
          {!stacked && <>&nbsp;</>}
        </>
      ) : (
        <MuiTextField {...props} />
      )}
    </>
  );
};

export default TextField;
