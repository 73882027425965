import React, { useState } from "react";
import type { FC } from "react";
import { makeStyles, Grid, Button, Box } from "@material-ui/core";
import { security } from "../Security";
import { Mode } from "src/types/IForm";
import TextField from "../Forms/TextField";
import useAuth from "src/hooks/useAuth";
import { useEffect } from "react";
import useComments from "src/hooks/useComments";
import { useParameters } from "src/contexts/ParametersContext";
import { submitError, submitSuccess } from "../Forms/FormikHelper";
import Emitter from "src/services/emitter";
export interface CommentCodeProps {
  label?: string;
  value?: string;
}
export interface ResultsFormProps {
  [key: string]: any;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  buttons: {
    "& > * + *": {
      marginLeft: 10,
    },
  },
}));

const ResultsForm: FC<ResultsFormProps> = ({
  className,
  children,
  limit,
  unit,
  commentCode,
  comment,
  rule,
  onCommentChange,
  mode = Mode.CREATE,
  ...rest
}) => {
  const classes = useStyles();
  const { user: loggedInUser } = useAuth();
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const { updateComment } = useComments();
  const { getSelectedParamValue } = useParameters();

  const handleSave = () => {
    const progCode = getSelectedParamValue("programs", { program_Code: null })
      .program_Code;

    if (!progCode) {
      submitError("no program code");
      return;
    }

    updateComment(
      {
        commentIndex: commentCode,
        commentText: commentValue,
      },
      progCode
    )
      .then((response) => {
        submitSuccess("Comment Saved");

        if (onCommentChange) {
          onCommentChange({
            commentIndex: commentCode,
            commentText: commentValue,
          });
        }
        Emitter.emit("COMMENT", { action: "COMMENT_UPDATED" });
        setIsEditingComment(false);
      })
      .catch((e) => submitError(e));
  };

  useEffect(() => {
    setCommentValue(comment);
  }, [comment]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          readonly
          label="Limit"
          variant="outlined"
          className={classes.root}
          value={limit ?? ""}
        />

        <TextField
          readonly
          label="Unit"
          variant="outlined"
          value={unit ?? ""}
          className={classes.root}
        />

        <TextField
          readonly
          label="Comment Code"
          variant="outlined"
          className={classes.root}
          value={commentCode ?? ""}
        />

        <TextField
          readonly={!isEditingComment}
          label="Comment"
          variant="outlined"
          multiline
          rows={4}
          className={classes.root}
          value={commentValue ?? ""}
          onEdit={
            mode !== Mode.READONLY &&
            security(loggedInUser, "comments:edit") &&
            commentCode !== ""
              ? () => setIsEditingComment(true)
              : null
          }
          onChange={(event) => setCommentValue(event.target.value)}
        />

        {isEditingComment && (
          <Box mt={2} className={classes.buttons}>
            <Button
              onClick={() => {
                setCommentValue(comment);
                setIsEditingComment(false);
              }}
            >
              Cancel
            </Button>

            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ResultsForm;
