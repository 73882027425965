import React, { useState } from "react";
import type { FC } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import useDebounce from "src/hooks/useDebounce";

export interface ProgramProps {
  label?: string;
  value?: string;
}
export interface FilterFormProps {}

interface IParameterOption {
  name: string;
  id: number;
}

interface AutocompleteParameterProps {
  [key: string]: any;
}

const AutocompleteParameter: FC<AutocompleteParameterProps> = ({
  state,
  parameter,
  selectOption,
  setParameterSearchTerm,
  label,
  disabled = false,
  defaultValue,
  required,
  helperText,
  error,
  onChange,
  name,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(
    () => {
      setParameterSearchTerm({ parameter, searchTerm: debouncedSearchTerm });
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (!defaultValue) return;

    const defaultProgram =
      (state?.options[parameter] &&
        state?.options[parameter].find((o) => o.id === defaultValue)) ??
      null;

    if (defaultProgram) selectOption({ parameter, option: defaultProgram });
  }, [defaultValue, state?.options[parameter]]);

  const handleOnChange = (event, value) => {
    if (onChange) onChange(event, value);
    selectOption({ parameter, option: value });
  };

  return (
    <Autocomplete
      {...rest}
      options={state?.options[parameter] ?? []}
      value={state?.selections[parameter] ?? null}
      getOptionLabel={(option: IParameterOption) => option?.name}
      filterOptions={(x) => x}
      getOptionSelected={(option, value) => option.name === value.name}
      onInputChange={(event, value) => setSearchTerm(value)}
      onChange={handleOnChange}
      id={`${parameter}`}
      autoComplete
      includeInputInList
      loading={state?.isLoading[parameter]}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          required={required}
          label={label}
          variant="outlined"
          margin="dense"
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {state?.isLoading[parameter] ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteParameter;
