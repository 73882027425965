import React, { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import AuthGuard from "./components/Security/AuthGuard";
import GuestGuard from "./components/Security/GuestGuard";
import DashboardLayout from "./layouts/DashboardLayout";
import Loader from "./components/Loader";
import MainLayout from "./layouts/MainLayout";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

const Forgot = Loadable(lazy(() => import("./pages/authentication/Forgot")));

const Overview = Loadable(lazy(() => import("./pages/home")));

// Error pages

const NotFound = Loadable(lazy(() => import("./pages/system/NotFound")));

// Other pages
const Audit = Loadable(lazy(() => import("./pages/audit/Browse")));
const Users = Loadable(lazy(() => import("./pages/users/Browse")));
const CreateUser = Loadable(lazy(() => import("./pages/users/Create")));
const UserDetails = Loadable(lazy(() => import("./pages/users/Details")));

// Legal

const Terms = Loadable(lazy(() => import("./pages/legal/Terms")));
const Privacy = Loadable(lazy(() => import("./pages/legal/Privacy")));

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: (
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot",
        element: <Forgot />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
    ],
  },

  {
    path: "app",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: <Overview />,
      },
      {
        path: "audit",
        element: <Audit />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/create",
        element: <CreateUser />,
      },
      {
        path: "users/edit/:id",
        element: <UserDetails />,
      },
    ],
  },
  {
    path: "*",
    children: [
      {
        path: "401",
        element: <NotFound />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <NotFound />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
