import React from "react";
// import { useHistory } from 'react-router-dom';
import type { FC } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  Badge,
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import type { Theme } from "src/theme";
import useAuth from "src/hooks/useAuth";
import { submitError } from "src/components/Forms/FormikHelper";

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
}));

const Logout: FC = () => {
  const classes = useStyles();
  // const history = useHistory();
  const { logout } = useAuth();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout(false);
    } catch (err) {
      submitError(err);
    }
  };

  return (
    <>
      <Tooltip title="Logout">
        <Badge
          color="primary"
          variant="standard"
          classes={{ badge: classes.badge }}
        >
          <IconButton color="inherit" onClick={handleLogout}>
            <SvgIcon fontSize="small">
              <ExitToAppIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
    </>
  );
};

export default Logout;
