import React, { useEffect, useState } from "react";
import type { FC } from "react";
import { IconButton } from "@material-ui/core";
import Header from "src/components/Header";
import AuditLogTable from "src/components/Tables/AuditLogTable";
import CloseIcon from "@material-ui/icons/Close";
import { useAudits } from "src/contexts/AuditContext";
import IRule from "src/types/IRule";
import { usePagination } from "src/components/Tables/@hooks/usePagination";
import { Sorting } from "@devexpress/dx-react-grid";

interface AutitLogProps {
  onClose?: () => void;
  rule?: IRule;
}

const AuditLog: FC<AutitLogProps> = ({ onClose, rule }) => {
  const { state, getAudits, getAuditByRule } = useAudits();
  const { state: pagerState, setOffset, setSize } = usePagination();

  const [sorting, setSorting] = useState<Sorting[]>([
    { columnName: "changedOn", direction: "desc" },
  ]);

  useEffect(() => {
    if (state.isLoading.audits) return;

    if (rule) {
      getAuditByRule(rule, {
        params: {
          limit: pagerState.limit,
          offset: pagerState.offset,
          sort: sorting.map(
            (sort) => (sort.direction === "asc" ? "+" : "-") + sort.columnName
          ),
        },
      });
    } else {
      getAudits({
        params: {
          limit: pagerState.limit,
          offset: pagerState.offset,
          sort: sorting.map(
            (sort) => (sort.direction === "asc" ? "+" : "-") + sort.columnName
          ),
        },
      });
    }
  }, [pagerState, rule, sorting]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header title="Audit Log" />
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <AuditLogTable
        totalCount={state.totalCount}
        setCurrentPage={setOffset}
        setPageSize={setSize}
        pageSize={pagerState.limit}
        currentPage={pagerState.offset}
        rows={state.audits}
        isLoading={state.isLoading?.audits}
        sorting={sorting}
        setSorting={setSorting}
      />
    </>
  );
};

export default AuditLog;
