import React, { useEffect, useState } from "react";
import type { FC } from "react";
import { Backdrop, Box, makeStyles, Typography } from "@material-ui/core";

import type { Theme } from "src/theme";
import Emitter from "src/services/emitter";
import Loader from "../Loader";

export interface HeaderProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AppLoader: FC<HeaderProps> = ({ ...rest }) => {
  const classes = useStyles();

  const [showAppLoader, setShowAppLoader] = useState<boolean>(false);
  const [appLoaderMessage, setAppLoaderMessage] = useState<string>("");

  useEffect(() => {
    Emitter.on("APP", handleAppEvent, AppLoader);

    return () => {
      Emitter.off("APP", handleAppEvent, AppLoader);
    };
  }, []);

  const handleAppEvent = (payload) => {
    const { action, isLoading, message } = payload;
    switch (action) {
      case "LOADER":
        setShowAppLoader(isLoading);
        setAppLoaderMessage(message);
        break;
    }
  };

  return (
    <>
      {showAppLoader && (
        <Backdrop className={classes.backdrop} open={showAppLoader}>
          <Box>
            <Box>
              <Loader />
            </Box>
            <Box>
              <Typography variant="body2">{appLoaderMessage}</Typography>
            </Box>
          </Box>
        </Backdrop>
      )}
    </>
  );
};

export default AppLoader;
