import React from "react";
import type { FC } from "react";
import BaseTable from "../BaseTable";
import columns from "./columns";

export interface AuditLogTableProps {
  [key: string]: any;
}

const AuditLogTable: FC<AuditLogTableProps> = ({ ...rest }) => {
  return (
    <div>
      <BaseTable
        hasGrouping
        getRowId={(audit) => audit.id}
        columns={columns}
        exportFileName="audits.xlsx"
        hasSearch={false}
        {...rest}
      />
    </div>
  );
};

export default AuditLogTable;
