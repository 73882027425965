import type { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "src/contexts/AuthContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { createTheme } from "src/theme";
import { THEMES } from "./constants";
import { StylesProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtils";
import DayJSUtils from "@date-io/dayjs";

import { AppProvider } from "./contexts/AppContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ParametersProvider } from "./contexts/ParametersContext";
import { RulesProvider } from "./contexts/RulesContext";

import ServiceWorkerNotification from "src/components/ServiceWorkerNotification";

const theme = createTheme({
  theme: THEMES.LIGHT,
});
interface Props {
  [key: string]: any;
}

const ContextProviders = ({ children }) => (
  <AppProvider>
    <AuthProvider>
      <SettingsProvider>
        <ParametersProvider>
          <RulesProvider>
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              {children}
            </MuiPickersUtilsProvider>
          </RulesProvider>
        </ParametersProvider>
      </SettingsProvider>
    </AuthProvider>
  </AppProvider>
);

const Root: FC<Props> = ({ children, ...rest }) => {
  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <SnackbarProvider dense maxSnack={3}>
              <SnackbarUtilsConfigurator />
              <ContextProviders>
                <ServiceWorkerNotification />
                {children}
              </ContextProviders>
            </SnackbarProvider>
          </BrowserRouter>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  );
};

export default Root;
