import React, { createContext, ReactNode, useContext, useReducer } from "react";

import type { FC } from "react";

interface AppContextValue {
  state?: AppState | null;
  dispatch?: (value: Action) => void;
  openSection?: (section: Section, metaData?: any) => void;
  closeSection?: (section: Section) => void;
  swInit?: () => void;
  swUpdate?: (registeration: any) => void;
}

const AppContext = createContext<AppContextValue>({});
const useApp = (): AppContextValue => useContext(AppContext);

type SectionAction = {
  type: "SECTION";
  payload: {
    section: Section;
    status: Status;
    metaData?: any;
  };
};

type ServiceWorkerAction = {
  type: "SERVICE_WORKER";
  payload: {
    action: string;
    registeration?: any;
  };
};

type Action = SectionAction | ServiceWorkerAction;

export enum Section {
  RULE,
  AUDIT,
}

export enum Status {
  OPEN,
  CLOSED,
  NEW,
  EDIT,
}

interface AppState {
  section?: any;
  serviceWorkerInitialized?: boolean;
  serviceWorkerUpdated?: boolean;
  serviceWorkerRegistration?: any;
}

const reducer = (state: AppState, action: Action): AppState => {
  let newState = state;

  switch (action.type) {
    case "SECTION": {
      const { section, status, metaData } = action.payload;
      newState = {
        ...state,
        section: { ...state.section, [section]: { status, metaData } },
      };
      break;
    }

    case "SERVICE_WORKER": {
      const { action: actionType, registeration } = action.payload;

      if (actionType === "SW_INIT") {
        newState = {
          ...state,
          serviceWorkerInitialized: !state.serviceWorkerInitialized,
        };
      }

      if (actionType === "SW_UPDATE") {
        newState = {
          ...state,
          serviceWorkerUpdated: !state.serviceWorkerUpdated,
          serviceWorkerRegistration: registeration,
        };
      }

      break;
    }

    default: {
      return state;
    }
  }
  return newState;
};

interface AppProps {
  children: ReactNode;
}

const AppProvider: FC<AppProps> = ({ children }: AppProps) => {
  const initialState: AppState = {
    section: {
      [Section.AUDIT]: { status: Status.CLOSED, metaData: {} },
      [Section.RULE]: { status: Status.CLOSED, metaData: {} },
    },
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const openSection = (section: Section, metaData?: any) =>
    dispatch({
      type: "SECTION",
      payload: { section, status: Status.OPEN, metaData },
    });

  const closeSection = (section: Section) =>
    dispatch({ type: "SECTION", payload: { section, status: Status.CLOSED } });

  const swInit = () =>
    dispatch({ type: "SERVICE_WORKER", payload: { action: "SW_INIT" } });

  const swUpdate = (registeration?: any) =>
    dispatch({
      type: "SERVICE_WORKER",
      payload: { action: "SW_UPDATE", registeration },
    });

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      openSection,
      closeSection,
      swInit,
      swUpdate,
    }),
    [state]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppProvider, useApp };
