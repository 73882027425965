import type { Item } from "./item";
import type { IUser } from "src/types/IUser";

import Emitter from "src/services/emitter";

import {
  Home,
  ListAlt,
  People,
  PlaylistAdd,
  YoutubeSearchedFor,
} from "@material-ui/icons";

import { config } from "src/config";

export interface Section {
  items: Item[];
  subheader: string;
}

export const useSections = (user: IUser, navigate): Section[] => {
  return [
    {
      subheader: "",
      items: [
        {
          access: "dashboard",
          title: "Dashboard",
          icon: Home,
          href: config.APP_HOME,
          onClick: (event) => {
            event.preventDefault();
            if (navigate) navigate(config.APP_HOME);
            Emitter.emit("NAVIGATE", { action: "HOME" });
          },
        },
        {
          access: "dashboard",
          title: "Restart Search",
          icon: YoutubeSearchedFor,
          href: "/restart",
          onClick: (event) => {
            event.preventDefault();
            if (navigate) navigate(config.APP_HOME);
            Emitter.emit("PARAMETERS", { action: "CLEAR_PARAMETERS" });
          },
        },
        {
          access: "rules:create",
          title: "New Rule",
          icon: PlaylistAdd,
          href: "/rules/new",
          onClick: (event) => {
            event.preventDefault();
            Emitter.emit("RULE", { action: "CREATE_NEW" });
          },
        },
        {
          access: "audit",
          title: "Audit Log",
          icon: ListAlt,
          href: "/app/audit",
        },
        {
          access: "users",
          title: "Users",
          icon: People,
          href: "/app/users",
        },
      ],
    },
  ];
};
