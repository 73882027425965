import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { config } from "src/config";
import { createUser } from "src/factory/user";
import type { IUser } from "src/types/IUser";

var instance = axios.create({
  baseURL: config.BASE_URL,
  // timeout: 5000,
  // headers: { "X-Custom-Header": "foobar" },
  paramsSerializer: (params) => {
    const parts = [];

    const encode = (val) => {
      return encodeURIComponent(val)
        .replace(/%3A/gi, ":")
        .replace(/%24/g, "$")
        .replace(/%2C/gi, ",")
        .replace(/%20/g, "+")
        .replace(/%5B/gi, "[")
        .replace(/%5D/gi, "]");
    };

    const convertPart = (key, val) => {
      if (val instanceof Date) val = val.toISOString();
      else if (val instanceof Object) val = JSON.stringify(val);

      parts.push(encode(key) + "=" + encode(val));
    };

    Object.entries(params).forEach(([key, val]) => {
      if (val === null || typeof val === "undefined") return;

      if (Array.isArray(val))
        val.forEach((v, i) => convertPart(`${key}[${i}]`, v));
      else convertPart(key, val);
    });

    return parts.join("&");
  },
});

// This sets the mock adapter on the default instance
var mock = new MockAdapter(instance);

// Mock any GET request to /users
// arguments for reply are (status, data, headers)

const users: IUser[] = Array(10)
  .fill("")
  .map((x) => createUser().faker());

mock.onGet("/users").reply(200, users).onAny().passThrough();

mock.restore();

export default instance;
