import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { format } from "src/utils/date";

const DateFormatter = ({ value }) => format(value);

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

export { DateTypeProvider, DateFormatter };
