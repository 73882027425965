import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "src/serviceWorker";
import App from "./App";
import Root from "./Root";
import Emitter from "./services/emitter";
import { config } from "src/config";

ReactDOM.render(
  <React.StrictMode>
    <Root>
      <App />
    </Root>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

console.log(
  `${config.APP_NAME} - Publish Date: ${process.env.REACT_APP_PUBLISH_DATE}`
);
console.log(`${config.APP_NAME}  - Environment: ${process.env.NODE_ENV}`);
console.log(`${config.APP_NAME}  - Config:`, config);

serviceWorker.register({
  onSuccess: () => Emitter.emit("SERVICE_WORKER", { action: "SW_INIT" }),
  onUpdate: (registeration) =>
    Emitter.emit("SERVICE_WORKER", { action: "SW_UPDATE", registeration }),
});
