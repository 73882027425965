//@ts-nocheck

import React, { useState, useCallback, useRef, useMemo } from "react";
import type { FC } from "react";

import {
  Grid,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
  TableColumnReordering,
  DragDropProvider,
  TableColumnResizing,
  ExportPanel,
  TableFixedColumns,
  TableSelection,
  TableGroupRow,
  GroupingPanel,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SortingState,
  SearchState,
  PagingState,
  SelectionState,
  IntegratedSelection,
  GroupingState,
  IntegratedGrouping,
  FilteringState,
  CustomPaging,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import FileSaver from "file-saver";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import CustomExport from "./@plugins/CustomExport";

import { BooleanTypeProvider } from "./@components/providers/BooleanTypeProvider";
import { DateTypeProvider } from "./@components/providers/DateTypeProvider";
import { SeverityTypeProvider } from "./@components/providers/SeverityTypeProvider";

import Loader, { LoaderType } from "../Loader";

import Cell from "./@components/cells/Cell";

import TableHeaderContent from "./@components/TableHeaderContent";
interface Props {
  [key: string]: any;
  rows?: any[];
  totalCount?: number;
  pageSize?: number;
  currentPage?: number;
  isLoading?: boolean;
}

const ColumnChooserItemComponent = (props) =>
  props.item.column.name !== "command" ? (
    <ColumnChooser.Item {...props} />
  ) : null;

const BaseTable: FC<Props> = ({
  columns = [],
  rows = [],
  exportFileName = "Data.xlsx",
  hasCustomExport,
  onOpen,
  onDelete,
  onAudit,
  onEdit,
  isLoading = false,
  totalCount = 0,
  pageSize = 25,
  currentPage = 0,
  sorting = [],
  setTotalCount,
  hasCommands = true,
  hasSelection = false,
  selection = [],
  setCurrentPage,
  setSorting,
  setPageSize,
  setSearchValue,
  setFilters,
  hasGrouping = false,
  onSelect,
  getRowId,
  initialSelections = [],
  onCommentCodeChange,
  hasMemorySorting = false,
  hasPagination = true,
  pageSizes = [25, 50, 75],
  hasSearch = true,
  hasSorting = true,
  onExportClick,
}) => {
  const columnWidths = useMemo(
    () =>
      columns.map((column, index) => ({
        columnName: column.name,
        width: column?.width ? column?.width : 150,
        align: column?.name === "command" ? "right" : null,
      })),
    [columns]
  );

  const defaultColumns = useMemo(
    () => columns.filter((x) => !x.default).map((x) => x.name),
    [columns]
  );

  const [hiddenColumnNames, setHiddenColumnNames] = useState(defaultColumns);

  const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      FileSaver.saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        exportFileName
      );
    });
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      console.log("options", options);
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  const TableComponent = useCallback(
    (props) => {
      return isLoading ? (
        <VirtualTable.Table {...props}>
          <Loader loaderType={LoaderType.RING} />
        </VirtualTable.Table>
      ) : (
        <VirtualTable.Table {...props} />
      );
    },
    [isLoading]
  );

  const getSelectionIndexes = (rows, selection, getRowId) => {
    let currentIndex = -1;
    return rows.reduce((acc, val) => {
      currentIndex++;
      if (selection.includes(getRowId(val))) {
        return [...acc, currentIndex];
      }
      return acc;
    }, []);
  };

  const CustomCells = (props) => {
    return (
      <Cell
        {...props}
        onOpen={onOpen}
        onDelete={onDelete}
        onAudit={onAudit}
        onEdit={onEdit}
        onCommentCodeChange={onCommentCodeChange}
      />
    );
  };

  const cachedGetRowId = useCallback(getRowId, [rows]);

  return (
    <>
      <Grid rows={rows} columns={columns} getRowId={cachedGetRowId}>
        <BooleanTypeProvider for={["active"]} />
        <DateTypeProvider
          for={["createdDate", "modifiedDate", "changedOn", "sampledDate"]}
        />
        <SeverityTypeProvider for={["severity"]} />
        <SelectionState selection={selection} onSelectionChange={onSelect} />
        <IntegratedSelection />

        {hasSorting && (
          <SortingState sorting={sorting} onSortingChange={setSorting} />
        )}
        {hasMemorySorting && (
          <>
            <SortingState
              defaultSorting={[{ columnName: "sampleNo", direction: "asc" }]}
            />
            <IntegratedSorting />
          </>
        )}
        {hasPagination && (
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
        )}
        <DragDropProvider />
        <GroupingState
          columnExtensions={[{ columnName: "command", groupingEnabled: false }]}
        />
        <IntegratedGrouping />
        <SearchState />
        {hasPagination && <CustomPaging totalCount={totalCount} />}

        <FilteringState onFiltersChange={setFilters} />

        <VirtualTable
          height="700px"
          cellComponent={CustomCells}
          columnExtensions={[
            { columnName: "command", align: "right" },
            // { columnName: "commentText", wordWrapEnabled: true },
          ]}
          tableComponent={TableComponent}
        />

        {hasPagination && <PagingPanel pageSizes={pageSizes} />}

        <TableColumnResizing defaultColumnWidths={columnWidths} />
        <TableHeaderRow
          contentComponent={TableHeaderContent}
          showSortingControls={hasSorting || hasMemorySorting}
        />

        {hasSelection && <TableSelection />}
        <Toolbar />

        {hasSearch && <SearchPanel />}

        <TableColumnVisibility
          hiddenColumnNames={[...hiddenColumnNames, !hasCommands && "command"]}
          onHiddenColumnNamesChange={(value) => {
            setHiddenColumnNames(value);
          }}
        />

        <ColumnChooser itemComponent={ColumnChooserItemComponent} />
        <TableColumnReordering
          defaultOrder={columns.map((column) => column.name)}
        />

        <TableGroupRow />

        {hasGrouping && (
          <>
            <GroupingPanel
              showSortingControls={hasSorting || hasMemorySorting}
            />
          </>
        )}

        <TableFixedColumns
          leftColumns={[TableSelection.COLUMN_TYPE]}
          rightColumns={["command"]}
        />

        {hasCustomExport ? (
          <CustomExport onClick={onExportClick} />
        ) : (
          <ExportPanel startExport={startExport} />
        )}
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns.filter((x) => x.name !== "command")}
        selection={getSelectionIndexes(rows, selection, getRowId)}
        onSave={onSave}
      />
    </>
  );
};

export default BaseTable;
